import { groupBy } from "lodash";

import { AllergenType } from "types";
import { DishTypeCategory, LocalDishType, MealListSortTypeEnum } from "types/mainTypes";

import { Config } from "../services/configuration/eatSmartConfig";

/**
 * Filters the available dish types based on the given parameters.
 */
export const filterAvailableDishTypes = (
	fridgeDishTypes: LocalDishType[],
	availableDishTypes: Set<string>,
	allergens: AllergenType[],
): LocalDishType[] => {
	if (fridgeDishTypes) {
		return fridgeDishTypes.filter(
			(dishType) =>
				availableDishTypes.has(dishType.id) &&
				!allergens.some(
					(a: AllergenType) => dishType.allergens && dishType.allergens.includes(a),
				),
		);
	} else {
		return [];
	}
};
/**
 * Filters the available dish type IDs based on the given parameters.
 */
export const filterAvailableDishTypeIds = (
	fridgeDishTypes: LocalDishType[],
	availableDishTypes: Set<string>,
	allergens: AllergenType[],
): string[] => {
	const filteredDishTypes = filterAvailableDishTypes(
		fridgeDishTypes,
		availableDishTypes,
		allergens,
	);
	return filteredDishTypes.map((dishType) => dishType.id);
};

export const sortAndGroupDishTypesByCategory = (dishTypes: LocalDishType[]) => {
	return groupBy(
		dishTypes.sort((a, b) => {
			const categoryA = a.category ?? DishTypeCategory.MEAL;
			const categoryB = b.category ?? DishTypeCategory.MEAL;

			const orderA = Config.mealListOrder.categoryOrder[categoryA];
			const orderB = Config.mealListOrder.categoryOrder[categoryB];

			return orderA - orderB;
		}),
		"category",
	);
};

function sortDishTypesByName(
	a: LocalDishType,
	b: LocalDishType,
	language: "primary" | "secondary",
) {
	const descriptionA = a.description?.[language]?.name?.toLowerCase() ?? "";
	const descriptionB = b.description?.[language]?.name?.toLowerCase() ?? "";

	if (Config.mealListOrder.dishTypeOrderDirection === "DESC") {
		return descriptionA < descriptionB ? 1 : -1;
	} else {
		return descriptionA < descriptionB ? -1 : 1;
	}
}

function sortDishTypesByPrice(a: LocalDishType, b: LocalDishType) {
	const priceA = parseInt(a.price?.current ?? "0");
	const priceB = parseInt(b.price?.current ?? "0");
	if (Config.mealListOrder.dishTypeOrderDirection === "DESC") {
		return priceB - priceA;
	} else {
		return priceA - priceB;
	}
}

export const sortDishTypes = (
	dishTypes: LocalDishType[],
	selectedLanguage: "primary" | "secondary",
) => {
	const sortedDishTypesByCategory = sortAndGroupDishTypesByCategory(dishTypes);
	return Object.values(sortedDishTypesByCategory)
		.map((item) => {
			return item.sort((a: LocalDishType, b: LocalDishType) => {
				switch (Config.mealListOrder.dishTypeOrder) {
					case MealListSortTypeEnum.NAME:
						return sortDishTypesByName(a, b, selectedLanguage);
					case MealListSortTypeEnum.PRICE: {
						return sortDishTypesByPrice(a, b);
					}
					default: {
						return a.id.localeCompare(b.id);
					}
				}
			});
		})
		.flat();
};
