import { useMemo } from "react";
import { selectDishTypes } from "slices/dishesSlice";

import { useAppSelector } from "hooks/utilsHooks/storeHooks";
import { LocalDishType } from "types/mainTypes";

export const useDishTypes = (): { data: LocalDishType[]; isFridgeEmpty: boolean } => {
	const dishTypes = useAppSelector(selectDishTypes) || [];
	const isFridgeEmpty = useMemo(() => {
		return !dishTypes.length || dishTypes.every((dt) => !dt?.dishes || !dt.dishes.length);
	}, [dishTypes]);
	return {
		data: dishTypes,
		isFridgeEmpty,
	};
};
