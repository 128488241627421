import { getIsMasterTablet } from "../browser/localStorage";

export const shouldTrackCloudwatch = () => {
	const appMode = import.meta.env.MODE;
	const isMasterTablet = getIsMasterTablet();

	if (!isMasterTablet) {
		return false;
	}

	if (appMode === "production") {
		return true;
	} else if (appMode === "staging") {
		const hasStagingAnalyticsOverride = new URLSearchParams(window.location.search).get(
			"useCloudwatch",
		);
		return hasStagingAnalyticsOverride === "true";
	}

	return false;
};
