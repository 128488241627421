import { Typography } from "@mui/material";
import parse from "html-react-parser";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { getFridgeId } from "utils/browser/localStorage";
import { getShortenedFridgeId } from "utils/utils";

import ErrorModalImage from "assets/illustrations/errorModalImage.svg";
import { ErrorBox } from "components/primitives/ErrorBox";
import { ModalContentWrapper } from "components/primitives/ModalContentWrapper";
import { ModalTitle } from "components/primitives/ModalTitle";
import { useCountdown } from "hooks/timers";

import { BasicModal } from "./BasicModal";

type ConnectionErrorModalProps = {
	appConnected: boolean;
	isSubscriptionBroken?: boolean;
};

export const ConnectionErrorModal = ({
	appConnected,
	isSubscriptionBroken,
}: ConnectionErrorModalProps) => {
	const fridgeId = getFridgeId();
	const shortenedFridgeId = getShortenedFridgeId(fridgeId);
	const [isOpen, setIsOpen] = useState(
		isSubscriptionBroken !== undefined && isSubscriptionBroken ? false : !appConnected,
	);
	const { countdown } = useCountdown(15);

	useEffect(() => {
		if (isSubscriptionBroken && countdown === 0) {
			setIsOpen(true);
		}
	}, [countdown, isSubscriptionBroken]);

	return (
		<>
			<BasicModal
				open={isOpen}
				fullHeight={true}
				closable={false}
				modalContent={<ErrorModalContent fridgeId={shortenedFridgeId} />}
			/>
		</>
	);
};

const ErrorModalContent = ({ fridgeId }: { fridgeId: string }) => {
	const { t } = useTranslation();
	return (
		<ModalContentWrapper>
			<ModalTitle variant={"promoHeader"}>{t("modals.error.title")}</ModalTitle>
			<Typography variant={"promoText"} mb={5}>
				{t("modals.error.body")}
			</Typography>
			<ErrorModalImage />
			<ErrorBox mt={"auto"}>
				<Typography
					display={"flex"}
					variant={"ctaSubtextBold"}
					color={"white"}
					textAlign={"center"}
				>
					{parse(t("modals.error.buttonText"))}
				</Typography>
			</ErrorBox>
			<Typography mt={1} fontWeight={600}>
				{t("modals.error.fridgeId", { fridgeId: fridgeId })}
			</Typography>
		</ModalContentWrapper>
	);
};
