import { createEnv } from "@t3-oss/env-core";
import { z } from "zod";

const booleanScheme = z
	.string()
	.optional()
	.transform((s) => {
		if (s !== undefined) {
			return s === "true";
		} else {
			return false;
		}
	});

export const env = createEnv({
	server: {
		POEDITOR_API_KEY: z.string(),
		POEDITOR_PROJECT_ID: z.string(),
		POEDITOR_LANGUAGES: z.string(),
		POEDITOR_TRANSLATIONS_PATH: z.string(),
		SENTRY_AUTH_TOKEN: z.string(),
	},
	clientPrefix: "VITE_",
	client: {
		VITE_AWS_APPSYNC_URL: z.string().url({
			message: "Missing appsync URL env variable",
		}),
		VITE_AWS_APPSYNC_API_KEY: z.string().min(1, {
			message: "Missing appsync API key env variable",
		}),
		VITE_AWS_REGION: z.string().min(1, {
			message: "Missing AWS region env variable",
		}),
		VITE_CONNECTION_TIMEOUT: z.coerce
			.number({
				message: "Missing Appsync connection timeout env variable",
			})
			.default(90000),
		VITE_INACTIVE_TIMEOUT: z.coerce.number().default(50000),
		VITE_INITIAL_STATE_QUERY_REFETCH_INTERVAL_MS: z.coerce.number().default(3600000),
		VITE_INITIAL_STATE_QUERY_REFETCH_DEBOUNCE_RANGE_MS: z.coerce.number().default(10000),
		// Debug modal and tests variables
		VITE_MSW_ENABLED: booleanScheme,
		VITE_USE_MOCK_DATA: booleanScheme,
		VITE_FRIDGE_1_ID: z.string(),
		VITE_FRIDGE_2_ID: z.string(),
		VITE_VIRTUAL_FRIDGE_ID: z.string(),
		VITE_REVIEW_FRIDGE_ID: z.string(),
		VITE_DEV_CARRY_FRIDGE_ID: z.string(),
		// Opened door alarms variables
		VITE_ALARM_SOUND_1_URL: z.string(),
		VITE_ALARM_SOUND_2_URL: z.string(),
		VITE_ALARM_SOUND_3_URL: z.string(),
		VITE_TOTAL_ALARM_TIMEOUT: z.coerce.number().default(160),
		VITE_DISPLAY_WARNING_TIMEOUT: z.coerce.number().default(100),
		VITE_FIRST_ALARM_TIMEOUT: z.coerce.number().default(70),
		VITE_SECOND_ALARM_TIMEOUT: z.coerce.number().default(40),
		VITE_THIRD_ALARM_TIMEOUT: z.coerce.number().default(1),
		VITE_REFILL_DOOR_WARNING_DELAY: z.coerce.number().default(400),

		// Tracking tools
		VITE_MIXPANEL_PROJECT_ID: z.string(),
		VITE_SENTRY_DSN: z.string().optional(),

		VITE_PLACEHOLDER_DISHTYPE_IMAGE_URL: z.string(),
	},
	runtimeEnv: import.meta.env,
	emptyStringAsUndefined: true,
	skipValidation: false,
});
