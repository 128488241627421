import { gql } from "@apollo/client";

export const SWITCH_FRIDGE_MODE_MUTATION = gql`
	mutation UpdateFridge($input: FridgeUpdate!) {
		updateFridge(input: $input) {
			lighting {
				mode
			}
		}
	}
`;

export const TOGGLE_SPOTLIGHT_MODE_MUTATION = gql`
	mutation ToggleSpotlight($input: FridgeUpdate!) {
		updateFridge(input: $input) {
			dishes {
				types {
					id: id
					lighting {
						mode: mode
					}
				}
			}
		}
	}
`;

const DISH_TYPES_FRAGMENT = gql`
	fragment DishTypeDetail on Fridge {
		dishes {
			types {
				category
				id
				description {
					primary {
						composition
						description
						name
					}
					secondary {
						composition
						description
						name
					}
				}
				image {
					url
				}
				price {
					default
					current
					currency
				}
				weight
				allergens
				lighting {
					mode
				}
				dishes {
					id
					expiration
					position
				}
			}
		}
	}
`;

export const INITIAL_STATE_QUERY = gql`
	${DISH_TYPES_FRAGMENT}
	query Fridge($id: ID!) {
		fridge(id: $id) {
			id
			name
			access {
				qrCode {
					code
				}
			}
			...DishTypeDetail
			door {
				state
				lock {
					state
					timeout
				}
			}
			lighting {
				mode
			}
			transaction {
				orders {
					last {
						id
						type
						state
						user {
							id
							name
						}
						inventory {
							removed {
								id
								type {
									id
								}
							}
							added {
								id
								type {
									id
								}
							}
							updated {
								id
								type {
									id
								}
							}
						}
						payment {
							method {
								id
								type
							}
						}
						price {
							total {
								default
								current
								currency
							}
						}
					}
				}
			}
			segments {
				segment {
					type
					capacity
				}
			}
		}
	}
`;

export const FETCH_DISH_TYPES = gql`
	${DISH_TYPES_FRAGMENT}

	query RefetchPricesQuery($id: ID!) {
		fridge(id: $id) {
			...DishTypeDetail
		}
	}
`;
export const FRIDGE_SUBSCRIPTION = gql`
	subscription FridgeSubscription($id: ID!) {
		fridge(id: $id) {
			id
			access {
				qrCode {
					code
				}
			}
			dishes {
				types {
					id
					description {
						primary {
							composition
							description
							name
						}
						secondary {
							composition
							description
							name
						}
					}
					image {
						url
					}
					price {
						default
						current
						currency
					}
					weight
					allergens
					lighting {
						mode
					}
					dishes {
						id
						expiration
						position
					}
				}
			}
			door {
				state
				lock {
					state
					timeout
				}
			}
			lighting {
				mode
			}
			transaction {
				orders {
					last {
						id
						type
						state
						user {
							id
							name
						}
						inventory {
							removed {
								id
								type {
									id
								}
							}
							added {
								id
								type {
									id
								}
							}
							updated {
								id
								type {
									id
								}
							}
						}
						payment {
							method {
								id
								type
							}
						}
						price {
							total {
								default
								current
								currency
							}
						}
					}
				}
			}
			segments {
				segment {
					type
					capacity
				}
			}
		}
	}
`;

export const SEND_FEEDBACK_MUTATION = gql`
	mutation rateOrder($input: OrderFeedbackRatingUpdate!) {
		rateOrder(input: $input) {
			error {
				type
				message
			}
		}
	}
`;

export const GET_QR_CODE_QUERY = gql`
	query QRCodeQuery($id: ID!) {
		fridge(id: $id) {
			access {
				qrCode {
					code
				}
			}
			error {
				type
				message
			}
		}
	}
`;
