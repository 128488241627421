import { useMutation } from "@apollo/client";
import { selectFridgeLightingMode } from "slices/fridgeLightingSlice";
import { TabletTypeEnum, getTabletType } from "utils/browser/localStorage";
import { SWITCH_FRIDGE_MODE_MUTATION, TOGGLE_SPOTLIGHT_MODE_MUTATION } from "utils/gqlOperations";
import {
	createDefaultLight,
	createSpotlightLight,
	dishLightingStateCreator,
} from "utils/lightingUtils";

import { useDishTypes } from "hooks/useDishTypes";
import { useFridgeInformation } from "hooks/useFridgeInformation";
import { useAppSelector } from "hooks/utilsHooks/storeHooks";
import { FridgeDishesUpdate, LightingModeType } from "types";
import { DishTypeLightUpdate } from "types/mainTypes";

export const useFridgeLights = () => {
	const { id } = useFridgeInformation();
	const { data: dishTypes } = useDishTypes();
	const fridgeLightingMode = useAppSelector(selectFridgeLightingMode);
	const [updateLights] = useMutation<FridgeDishesUpdate>(TOGGLE_SPOTLIGHT_MODE_MUTATION);
	const [toggleFridgeLightMode] = useMutation(SWITCH_FRIDGE_MODE_MUTATION);
	const tabletType = getTabletType();

	const toggleFridgeLightingMode = (mode: LightingModeType) => {
		if (id == null || tabletType !== TabletTypeEnum.Master) {
			return; // cannot publish without an ID
		}

		toggleFridgeLightMode({
			variables: {
				input: {
					id: id,
					lighting: {
						mode: mode,
					},
				},
			},
		});
	};

	const toggleLight = (input: DishTypeLightUpdate[]) => {
		if (id == null || tabletType !== TabletTypeEnum.Master) {
			return; // cannot publish without an ID
		}

		updateLights({
			variables: {
				input: {
					id: id,
					dishes: {
						types: input,
					},
				},
			},
		});
	};

	const highlightSingleDishType = (
		toggleLight: (input: DishTypeLightUpdate[]) => void,
		availableDishTypeIds: Set<string>,
		highlightedDishTypeId: string | null,
	) => {
		const updatedLights = Array.from(availableDishTypeIds).map((dishTypeId) =>
			dishTypeId === highlightedDishTypeId
				? createSpotlightLight(dishTypeId)
				: createDefaultLight(dishTypeId),
		);
		toggleLight(updatedLights);
	};

	const highlightFilteredDishes = (
		filteredDishTypesIds: string[],
		availableDishTypeIds: Set<string>,
		toggleLight: (input: DishTypeLightUpdate[]) => void,
		highlightedDishTypeId: string | null,
	) => {
		if (highlightedDishTypeId && filteredDishTypesIds.includes(highlightedDishTypeId)) {
			highlightSingleDishType(toggleLight, availableDishTypeIds, highlightedDishTypeId);
		} else {
			toggleLight(dishLightingStateCreator(filteredDishTypesIds, availableDishTypeIds));
		}
	};

	return {
		toggleLight,
		toggleFridgeLightingMode,
		dishTypeLights: dishTypes?.map((type) => {
			return {
				id: type.id,
				lightingMode: type.lighting?.mode,
			};
		}),
		lights: fridgeLightingMode,
		highlightSingleDishType,
		highlightFilteredDishes,
	};
};
