import { useEffect, useState } from "react";
import { useAudioPlayer } from "react-use-audio-player";
import { Config } from "services/configuration/eatSmartConfig";

import { Maybe, TransactionType } from "types";

import { useCountdown } from "./timers";

export const useOpenDoorAlarm = (orderType?: Maybe<TransactionType>) => {
	const { alarms } = Config;
	const {
		displayWarningTimeout,
		firstAlarm,
		secondAlarm,
		thirdAlarm,
		totalAlarmTimeout,
		refillWarningTimeout,
	} = alarms;
	const warningDelay = totalAlarmTimeout;
	const playSoundParamFromUrl = new URLSearchParams(window.location.search).get("playSound");

	const isRefill = orderType === TransactionType.Refill;
	// When order type is refill, we delay the initial warning by 300s (5 minutes)
	// we have to set the delay to 400 so the first warning is triggered when countdown reaches 100s
	const initialTimer = isRefill ? refillWarningTimeout : warningDelay;
	const { countdown, resetCountdown, startCountdown } = useCountdown(initialTimer);
	const [displayOpenedDoorWarning, setDisplayOpenedDoorWarning] = useState<boolean>(false);

	const { load: alarm1Load, play: alarm1Play, stop: alarm1Stop } = useAudioPlayer();
	const { load: alarm2Load, play: alarm2Play, stop: alarm2Stop } = useAudioPlayer();
	const { load: alarm3Load, play: alarm3Play, stop: alarm3Stop } = useAudioPlayer();

	const resetAlarmState = () => {
		alarm1Stop();
		alarm2Stop();
		alarm3Stop();
		if (displayOpenedDoorWarning) {
			setDisplayOpenedDoorWarning(false);
		}
	};

	const handleResetCountdown = () => {
		resetCountdown(true);
		setDisplayOpenedDoorWarning(false);
		resetAlarmState();
	};

	useEffect(() => {
		alarm1Load(firstAlarm.sound, { loop: true });
		alarm2Load(secondAlarm.sound, { loop: true });
		alarm3Load(thirdAlarm.sound, { loop: true });
		startCountdown(true);
		return () => {
			resetAlarmState();
			startCountdown(false);
		};
	}, []);

	useEffect(() => {
		if (countdown === displayWarningTimeout) {
			setDisplayOpenedDoorWarning(true);
		} else if (playSoundParamFromUrl && playSoundParamFromUrl === "true") {
			if (countdown === firstAlarm.timeout) {
				alarm1Play();
			} else if (countdown === secondAlarm.timeout) {
				alarm1Stop();
				alarm2Play();
			} else if (countdown === thirdAlarm.timeout) {
				alarm2Stop();
				alarm3Play();
			}
		}
	}, [countdown]);

	return {
		resetCountdown: () => handleResetCountdown(),
		displayOpenedDoorWarning,
	};
};
