import * as Sentry from "@sentry/react";
import { PaymentMethodType } from "gql/graphql";
import { useEffect, useState } from "react";
import { useIdleTimerContext } from "react-idle-timer";
import { batch } from "react-redux";
import { setSelectedLanguage } from "slices/appStateSlice";
import { selectDishTypes } from "slices/dishesSlice";
import { selectOrder, setOrder } from "slices/transactionSlice";

import { useAnalyticsContext } from "contexts/AnalyticsContext";
import { useAppDispatch, useAppSelector } from "hooks/utilsHooks/storeHooks";
import { Dish, Maybe, Order } from "types";
import { AppLanguage, OrderSummary } from "types/mainTypes";

type AWSOrderUpdate = {
	paymentMethod: Maybe<PaymentMethodType>;
	order: Order | null;
	orderDishes: Dish[];
	orderSummary: OrderSummary | null;
	finishOrder: () => void;
};

export const useOrder = (): AWSOrderUpdate => {
	const order = useAppSelector(selectOrder);
	const dishTypes = useAppSelector(selectDishTypes);
	const dispatch = useAppDispatch();
	const [orderSummary, setOrderSummary] = useState<OrderSummary | null>(null);
	const idleTimer = useIdleTimerContext();
	const analyticsContext = useAnalyticsContext();

	const removedItemsLength = order?.inventory?.removed?.length;
	useEffect(() => {
		if (!order?.inventory?.removed) {
			return;
		}
		const orderDishes = order.inventory.removed;
		const orderDishTypeIds = new Set(orderDishes.map((dish) => dish?.type?.id));
		const orderDishTypes = dishTypes?.filter((dishType) => orderDishTypeIds.has(dishType.id));

		setOrderSummary({
			orderDishTypes: orderDishTypes || [],
			orderPrice: order?.price,
		});
	}, [order, order?.state, removedItemsLength, dishTypes]);

	const finishOrder = () => {
		try {
			idleTimer.reset();
			analyticsContext.reset();
			batch(() => {
				dispatch(setSelectedLanguage(AppLanguage.PRIMARY));
				dispatch(setOrder(null));
			});
			return true;
		} catch (error) {
			Sentry.captureException(new Error("Error while finishing order: " + error));
			return false;
		}
	};

	return {
		paymentMethod: order?.payment?.method?.type || null,
		orderDishes: order?.inventory?.removed || [],
		order,
		orderSummary: orderSummary ?? null,
		finishOrder,
	};
};
