import { Global } from "@emotion/react";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { ErrorBoundary as SentryErrorBoundary } from "@sentry/react";
import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { env } from "services/configuration/env";

import { App } from "./App";
import { ConnectionErrorModal } from "./components/global/modals/ConnectionErrorModal";
import { initSentry } from "./services/external/sentry";
import { store } from "./store";
import EAT_PERFECT_THEME from "./utils/theme";

const enableMocking = async () => {
	if (!env.VITE_MSW_ENABLED) {
		return;
	}
	const { worker } = await import("./tests/mocks/browser");
	await worker.start();
	window.MSW = {};
};

initSentry();

enableMocking().then(() => {
	createRoot(document.getElementById("root") as HTMLElement).render(
		<StrictMode>
			<ThemeProvider theme={EAT_PERFECT_THEME}>
				<CssBaseline />
				<Global
					styles={{
						".MuiDrawer-root > .MuiPaper-root": {
							height: `calc(50% - ${320}px)`,
							overflow: "visible",
						},
						body: {
							overflow: "unset",
						},
					}}
				/>
				<SentryErrorBoundary fallback={<ConnectionErrorModal appConnected={false} />}>
					<Provider store={store}>
						<App />
					</Provider>
				</SentryErrorBoundary>
			</ThemeProvider>
		</StrictMode>,
	);
});
