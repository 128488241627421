import CloseIcon from "@mui/icons-material/Close";
import { Box, BoxProps, IconButton, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import parse from "html-react-parser";
import { forwardRef } from "react";
import { useTranslation } from "react-i18next";

import HowToBannerImage from "assets/howToBannerImage.webp";
import AppStoreBadge from "assets/icons/AppStoreBadge.svg";
import EatSmartIcon from "assets/icons/EatSmartIcon.svg";
import GooglePlayBadge from "assets/icons/GooglePlayBagde.svg";
import { useMixpanelTrackers } from "hooks/useMixpanelTrackers";

interface HowToBannerProps {
	isVisible: boolean;
	toggleBanner: () => void;
}

export const DownloadAppBanner = forwardRef(function HowToBanner(
	{ toggleBanner, isVisible, onClick }: BoxProps & HowToBannerProps,
	ref,
) {
	const { t } = useTranslation();
	const { trackDownloadAppBannerClose, trackDownloadAppBannerClick } = useMixpanelTrackers();

	const handleBannerClick = (e: React.MouseEvent<HTMLDivElement>) => {
		if (onClick) {
			trackDownloadAppBannerClick();
			onClick(e);
		}
	};

	const handleToggleBanner = (e: React.MouseEvent<HTMLButtonElement>) => {
		e.preventDefault();
		e.stopPropagation();
		trackDownloadAppBannerClose();
		toggleBanner();
	};

	return (
		<StyledWrapper ref={ref} isVisible={isVisible} onClick={(e) => handleBannerClick(e)}>
			<StyledIconButton onClick={(e) => handleToggleBanner(e)}>
				<CloseIcon color="inherit" fontSize="large" />
			</StyledIconButton>
			<StyledBannerHeader variant="cta">{parse(t("howToBanner.title"))}</StyledBannerHeader>
			<StyledList>
				<Typography>{t("howToBanner.step1")}</Typography>
				<Typography>{t("howToBanner.step2")}</Typography>
				<Typography>{t("howToBanner.step3")}</Typography>
			</StyledList>
			<img alt={"Person holding phone"} src={HowToBannerImage} width={328} height={332} />
			<StyledLogoWrapper>
				<EatSmartIcon />
				<GooglePlayBadge />
				<AppStoreBadge />
			</StyledLogoWrapper>
		</StyledWrapper>
	);
});

const StyledWrapper = styled(Box, {
	shouldForwardProp: (prop) => prop !== "isVisible",
})<BoxProps & { isVisible: boolean }>(({ theme, isVisible }) => ({
	width: "100%",
	display: "flex",
	flexDirection: "column",
	borderRadius: theme.shape.borderRadius,
	backgroundColor: "#353535",
	position: "relative",
	transition: "all 300ms ease",
	height: isVisible ? "345px" : "0",
	padding: isVisible ? "24px 12px 24px 24px" : 0,
	visibility: isVisible ? "visible" : "hidden",
	opacity: isVisible ? 1 : 0,

	"& img": {
		position: "absolute",
		bottom: 0,
		right: "12px",
		zIndex: 500,
	},
}));

const StyledList = styled(Box)(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	gap: theme.spacing(1),
	marginTop: theme.spacing(2),
	marginBottom: theme.spacing(5),

	"& p": {
		display: "flex",
		counterIncrement: "list-number",
		color: "white",
		fontSize: theme.typography.pxToRem(18),
		fontWeight: 500,
	},

	// Styled numbered list
	"& p:before": {
		boxSizing: "content-box",
		content: "counter(list-number)",
		display: "inline-flex",
		justifyContent: "center",
		alignItems: "center",
		width: theme.spacing(2),
		height: theme.spacing(2),
		padding: theme.spacing(0.5),
		borderRadius: 40,
		backgroundColor: theme.palette.copper.main,
		color: "white",
		marginRight: theme.spacing(1),
	},
}));

const StyledLogoWrapper = styled(Box)(({ theme }) => ({
	display: "flex",
	gap: theme.spacing(1),
}));

const StyledIconButton = styled(IconButton)(({ theme }) => ({
	color: "white",
	position: "absolute",
	top: theme.spacing(2),
	right: theme.spacing(2),
	padding: 0,
	zIndex: 1000,
}));

const StyledBannerHeader = styled(Typography)(({ theme }) => ({
	backgroundColor: "inherit",
	color: theme.palette.copper.main,
	"& span": {
		color: "white",
	},
}));
