import {
	CloudWatchClient,
	PutMetricDataCommand,
	PutMetricDataCommandInput,
} from "@aws-sdk/client-cloudwatch";

import { getFridgeId, getFridgeName } from "../../utils/browser/localStorage";
import { log } from "../../utils/logger";
import { Config } from "../configuration/eatSmartConfig";

const fridgeId = getFridgeId();
const fridgeName = getFridgeName() ?? undefined;
const { aws } = Config;

// eslint-disable-next-line max-len
// TODO: setup IAM role access: https://docs.aws.amazon.com/sdk-for-javascript/v3/developer-guide/javascript_sts_code_examples.html
const client = new CloudWatchClient({
	region: aws.region,
	credentials: {
		accessKeyId: import.meta.env.VITE_AWS_CW_ACCESS_KEY,
		secretAccessKey: import.meta.env.VITE_AWS_CW_SECRET_KEY,
	},
});

const keepAliveMetric: PutMetricDataCommandInput = {
	Namespace: "TerminalUptimeMonitoring",
	MetricData: [
		{
			MetricName: "Uptime",
			Dimensions: [
				{
					Name: "fridgeId",
					Value: fridgeId,
				},
				{
					Name: "fridgeName",
					Value: fridgeName,
				},
				{
					Name: "environment",
					Value: import.meta.env.MODE,
				},
			],
			Value: 1,
		},
	],
};

const command = new PutMetricDataCommand(keepAliveMetric);

export const sendCloudwatchMetric = (sendLogs: boolean) => {
	if (sendLogs) {
		client.send(command).catch(() => {
			// We do not need to handle anything asn we get the notifications in Cloudwatch
			return undefined;
		});
	} else {
		log("Cloudwatch check", "info", command);
	}
};
