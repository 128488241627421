import {
	Box,
	Button,
	Checkbox,
	FormControlLabel,
	FormControlLabelProps,
	IconButton,
	IconButtonProps,
	Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import { setOpenedDetailDishTypeId } from "slices/appStateSlice";
import { AllergensMapping } from "utils/AllergensMapping";

import MilkIcon from "assets/allergens/milk.svg";
import NutsIcon from "assets/allergens/nuts.svg";
import WheatIcon from "assets/allergens/wheat.svg";
import FilterIcon from "assets/icons/filterIcon.svg";
import { BasicModal } from "components/global/modals/BasicModal";
import { useFilters } from "hooks/useFilters";
import { useAppDispatch } from "hooks/utilsHooks/storeHooks";
import { AllergenType } from "types";

import { FilterChipLabel } from "./FilterChipLabel";
import { FiltersModal } from "./FilterModal";

const QuickFiltersConfig = [
	{
		label: "quickFilters.noGluten",
		value: AllergenType.Gluten,
		icon: WheatIcon,
	},
	{
		label: "quickFilters.noMilk",
		value: AllergenType.Milk,
		icon: MilkIcon,
	},
	{
		label: "quickFilters.noPeanuts",
		value: AllergenType.Nuts,
		icon: NutsIcon,
	},
] as const;

/**
 * Quick filters component
 * @constructor
 */
export const QuickFilters = () => {
	const { t } = useTranslation();
	const { setFilters, allergens } = useFilters();
	const dispatch = useAppDispatch();

	const handleFilterChange = (checkState: string | boolean, filterValue: AllergenType) => {
		if (checkState) {
			setFilters({ allergens: [...allergens, filterValue] });
		} else {
			setFilters({ allergens: allergens.filter((allergen) => allergen !== filterValue) });
		}
	};

	return (
		<QuickFiltersWrapper gap={2}>
			<Box display={"flex"} alignItems={"center"} width={"100%"}>
				{Object.values(QuickFiltersConfig)
					.filter((quickFilter) => !allergens.includes(quickFilter.value))
					.map((filter) => (
						<StyledFormControlLabel
							key={`quickFilter_${filter.value}`}
							control={<Checkbox />}
							checked={allergens.includes(filter.value)}
							onChange={(e, checked) => handleFilterChange(checked, filter.value)}
							isFiltered={allergens.includes(filter.value)}
							label={
								<FilterChipLabel
									label={t(filter.label)}
									isFiltered={allergens.includes(filter.value)}
									icon={filter.icon}
								/>
							}
						/>
					))}
				{allergens.length > 0 && (
					<StyledResetButton
						onClick={() => {
							setFilters({ allergens: [] });
							dispatch(setOpenedDetailDishTypeId(null));
						}}
					>
						Reset
					</StyledResetButton>
				)}
				<BasicModal
					toggleButton={
						<FilterButton activeFilters={allergens.length > 0} sx={{ color: "black" }}>
							<FilterIcon />
						</FilterButton>
					}
					modalContent={<FiltersModal />}
					closable={true}
					fullHeight={true}
				/>
			</Box>
			{allergens.length > 0 && (
				<StyledSelectedFilterWrapper>
					<Typography variant={"h5"} mr={3}>
						{t("quickFilters.labelAvoid")}
					</Typography>
					{AllergensMapping.filter((allergen) => allergens.includes(allergen.name)).map(
						(allergen) => (
							<StyledFormControlLabel
								key={`quickFilter_${allergen.name}`}
								control={<Checkbox />}
								checked={true}
								onChange={(e, checked) =>
									handleFilterChange(checked, allergen.name)
								}
								isFiltered={true}
								label={
									<FilterChipLabel
										label={t(`allergens.${allergen.name}`)}
										isFiltered={true}
									/>
								}
							/>
						),
					)}
				</StyledSelectedFilterWrapper>
			)}
		</QuickFiltersWrapper>
	);
};

const StyledResetButton = styled(Button)(({ theme }) => ({
	marginLeft: "auto",
	backgroundColor: "#FFFFFF25",
	color: theme.palette.common.white,
	padding: "8px 16px",
}));

const FilterButton = styled(IconButton, {
	shouldForwardProp: (prop) => prop !== "activeFilters",
})<IconButtonProps & { activeFilters?: boolean }>(({ theme, activeFilters }) => ({
	marginLeft: activeFilters ? theme.spacing(2) : "auto",
	display: "flex",
	justifyContent: "center",
	alignItems: "center",
	height: "47px",
	width: "47px",
	borderRadius: 10,
	backgroundColor: theme.palette.common.white,
}));

const QuickFiltersWrapper = styled(Box)(() => ({
	justifyContent: "flex-start",
	alignItems: "center",
	display: "flex",
	flexWrap: "wrap",
	width: "100%",
}));

const StyledFormControlLabel = styled(FormControlLabel, {
	shouldForwardProp: (prop) => prop !== "isFiltered",
})<FormControlLabelProps & { isFiltered: boolean }>(({ theme, isFiltered }) => ({
	backgroundColor: isFiltered ? theme.palette.common.white : "#FFFFFF25",
	padding: "8px 8px 8px 16px",
	marginLeft: 0,
	marginRight: "8px",
	"& .MuiCheckbox-root": {
		display: "none",
	},
	color: theme.palette.common.white,

	display: "flex",
	justifyContent: "flex-start",
	alignItems: "center",
	borderRadius: "80px",

	"span > .MuiBox-root": {
		fontWeight: 900,
	},
	'&[data-state="checked"]': {
		color: "$white",
	},
}));

const StyledSelectedFilterWrapper = styled(Box, {
	name: "StyledSelectedFilterWrapper",
})(({ theme }) => ({
	display: "flex",
	flexWrap: "wrap",
	width: "100%",
	color: theme.palette.common.white,
	gap: theme.spacing(1),
	alignItems: "center",
}));
