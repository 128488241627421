import { Box, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import parse from "html-react-parser";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { selectSelectedLanguage } from "slices/appStateSlice";
import { AllergensMapping } from "utils/AllergensMapping";
import { parseFridgeSegments } from "utils/parseFridgeSegments";
import { getDishTypeTextValue, parseInstructions } from "utils/utils";

import { useFridgeInformation } from "hooks/useFridgeInformation";
import { useAppSelector } from "hooks/utilsHooks/storeHooks";
import { LocalDishType } from "types/mainTypes";

import { AllergenChip } from "../AllergenChip";
import { DishTypeFridgePositionMap } from "../DishTypeFridgePositionMap";

const StyledDescriptionSection = styled(Box)(() => ({
	width: "60%",
	display: "flex",
	flexDirection: "column",
	justifyContent: "space-between",
}));

export const DescriptionTab = ({ dishType }: { dishType: LocalDishType }) => {
	const selectedLanguage = useAppSelector(selectSelectedLanguage);
	const { t } = useTranslation();

	const { segments } = useFridgeInformation();

	const parsedSegments = parseFridgeSegments(segments, dishType);

	// We have toi temporarily split the composition saved in DB as
	// it also contains the cooking instructions
	const description = getDishTypeTextValue(dishType, selectedLanguage, "description");
	const parsedDescription = parseInstructions(description);

	return (
		<Box display={"flex"} justifyContent={"space-between"} paddingTop={2}>
			<Box width={"30%"}>
				<Typography variant={"headlineMedium"}>
					{t("dishTypeDetail.descriptionTab.location")}
				</Typography>
				<Typography>
					{t("dishTypeDetail.descriptionTab.piecesLeft", {
						count: dishType.dishes?.length,
					})}
				</Typography>
				<DishTypeFridgePositionMap parsedSegments={parsedSegments} />
			</Box>
			<StyledDescriptionSection>
				<Box>
					{parse(
						Array.isArray(parsedDescription) ? parsedDescription[0] : parsedDescription,
					)}
				</Box>
				<Box display={"flex"} gap={1} flexWrap={"wrap"}>
					<Typography variant={"headlineMedium"} width={"100%"}>
						{t("dishTypeDetail.descriptionTab.allergens")}
					</Typography>
					{AllergensMapping.filter((allergen) =>
						dishType.allergens?.includes(allergen.name),
					).map((allergen) => (
						<AllergenChip
							allergen={allergen}
							key={`dishDetail_allergenList_${allergen.name}`}
						/>
					))}
				</Box>
			</StyledDescriptionSection>
		</Box>
	);
};
