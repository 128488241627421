import { LightingSegmentModeType } from "types";
import { DishTypeLightMode, DishTypeLightUpdate } from "types/mainTypes";

/**
 * Creates a default light for a dish type.
 * @param {string} dishTypeId - The ID of the dish type.
 * @return {DishTypeLightUpdate} - The dish type light update object.
 */
export const createDefaultLight = (dishTypeId: string): DishTypeLightUpdate => {
	return {
		id: dishTypeId,
		lighting: {
			mode: LightingSegmentModeType.Default,
		},
	};
};

/**
 * Creates a spotlight light for a dish type.
 * @param {string} dishTypeId - The ID of the dish type.
 * @return {DishTypeLightUpdate} - The spotlight light object.
 */
export const createSpotlightLight = (dishTypeId: string): DishTypeLightUpdate => {
	return {
		id: dishTypeId,
		lighting: {
			mode: LightingSegmentModeType.Spotlight,
		},
	};
};

/**
 * Creates dish state objects for each available dish type,
 * with the lighting mode based on whether the dish type is filtered.
 *
 * @param {string[]} filteredDishTypesIds - The ids of the dish types that are filtered.
 * @param {string[]} availableDishTypeIds - The ids of the available dish types.
 * @returns {Object[]} - An array of dish state objects.
 */
export const dishLightingStateCreator = (
	filteredDishTypesIds: string[],
	availableDishTypeIds: Set<string>,
): DishTypeLightUpdate[] => {
	const isHighlighted = (dishTypeId: string) =>
		filteredDishTypesIds.includes(dishTypeId)
			? LightingSegmentModeType.Spotlight
			: LightingSegmentModeType.Default;

	return Array.from(availableDishTypeIds).map((dishTypeId) => ({
		id: dishTypeId,
		lighting: {
			mode: isHighlighted(dishTypeId),
		},
	}));
};

export const getSpotlightDishTypeId = (dishTypeLights: DishTypeLightMode[]) => {
	return (
		dishTypeLights?.find(
			(dishTypeLight) => dishTypeLight.lightingMode === LightingSegmentModeType.Spotlight,
		)?.id ?? null
	);
};
