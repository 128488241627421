import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import type { RootState } from "store";

import { Config } from "../services/configuration/eatSmartConfig";
import { AppLanguage, AppState, Filters } from "../types/mainTypes";

type AppStateSlice = {
	locale: string;
	selectedLanguage: AppLanguage;
	filters: Filters;
	appState: AppState;
	openedDetailDishTypeId: string | null;
};

export const initialState: AppStateSlice = {
	locale: Config.common.defaultLocale,
	selectedLanguage:
		Config.common.defaultLocale === "cs-CZ" ? AppLanguage.PRIMARY : AppLanguage.SECONDARY,
	filters: {
		allergens: [],
	},
	appState: AppState.ACTIVE,
	openedDetailDishTypeId: null,
};

export const appStateSlice = createSlice({
	name: "appState",
	initialState,
	reducers: {
		setLocale: (state, { payload }: PayloadAction<string>) => {
			state.locale = payload;
		},
		setSelectedLanguage: (state, { payload }: PayloadAction<AppLanguage>) => {
			state.selectedLanguage = payload;
		},
		setFilters: (state, { payload }: PayloadAction<Filters>) => {
			state.filters = {
				...state.filters,
				...payload,
			};
		},
		setAppState: (state, { payload }: PayloadAction<AppState>) => {
			state.appState = payload;
		},
		setOpenedDetailDishTypeId: (state, { payload }: PayloadAction<string | null>) => {
			state.openedDetailDishTypeId = payload;
		},
	},
});

export const {
	setLocale,
	setOpenedDetailDishTypeId,
	setFilters,
	setAppState,
	setSelectedLanguage,
} = appStateSlice.actions;
export const selectLocale = (state: RootState) => {
	return state.appState.locale;
};
export const selectOpenedDetailDishTypeId = (state: RootState) => {
	return state.appState.openedDetailDishTypeId;
};
export const selectSelectedLanguage = (state: RootState) => state.appState.selectedLanguage;
export const selectAppState = (state: RootState) => state.appState.appState;
export const selectFilters = (state: RootState) => state.appState.filters;
export default appStateSlice.reducer;
