import { ApolloProvider } from "@apollo/client";
import { useEffect } from "react";
import { IdleTimerProvider, PresenceType } from "react-idle-timer";

import { DeviceContextProvider } from "contexts/DeviceContext";

import { ConnectionErrorModal } from "./components/global/modals/ConnectionErrorModal";
import { AnalyticsContextProvider } from "./contexts/AnalyticsContext";
import { useAppDispatch } from "./hooks/utilsHooks/storeHooks";
import { MealsListPage } from "./pages";
import { Config } from "./services/configuration/eatSmartConfig";
import "./services/i18n/config";
import { setAppState, setLocale } from "./slices/appStateSlice";
import { AppState } from "./types/mainTypes";
import { useFridgeApolloClient } from "./utils/ApolloClientProvider";
import { getLocale } from "./utils/browser/localStorage";

export const App = () => {
	const { client, isConnected, isSubscriptionBroken, setIsSubscriptionBroken } =
		useFridgeApolloClient();
	const dispatch = useAppDispatch();

	const locale = getLocale();

	useEffect(() => {
		setLocale(locale);
	}, [locale]);

	const onPresenceChange = (presence: PresenceType["type"]) => {
		dispatch(setAppState(presence as AppState));
	};

	return (
		<AnalyticsContextProvider>
			<ApolloProvider client={client}>
				<IdleTimerProvider
					timeout={Config.activityMonitor.inactiveTimeout}
					onPresenceChange={(presence) => onPresenceChange(presence.type)}
				>
					<DeviceContextProvider
						client={client}
						appConnected={isConnected}
						isSubscriptionBroken={isSubscriptionBroken}
						setIsSubscriptionBroken={setIsSubscriptionBroken}
					>
						<MealsListPage />
						{!isConnected && (
							<ConnectionErrorModal
								appConnected={isConnected}
								isSubscriptionBroken={isSubscriptionBroken}
							/>
						)}
					</DeviceContextProvider>
				</IdleTimerProvider>
			</ApolloProvider>
		</AnalyticsContextProvider>
	);
};
