/* eslint-disable */
import { TypedDocumentNode as DocumentNode } from "@graphql-typed-document-node/core";

export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = {
	[_ in K]?: never;
};
export type Incremental<T> =
	| T
	| { [P in keyof T]?: P extends " $fragmentName" | "__typename" ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
	ID: { input: string; output: string };
	String: { input: string; output: string };
	Boolean: { input: boolean; output: boolean };
	Int: { input: number; output: number };
	Float: { input: number; output: number };
};

export type AdyenPaymentRedirect = {
	__typename?: "AdyenPaymentRedirect";
	data?: Maybe<AdyenPaymentRedirectData>;
	method: AdyenPaymentRedirectMethod;
	url: Scalars["String"]["output"];
};

export type AdyenPaymentRedirectData = {
	__typename?: "AdyenPaymentRedirectData";
	MD: Scalars["String"]["output"];
	PaReq: Scalars["String"]["output"];
};

export enum AdyenPaymentRedirectMethod {
	Get = "GET",
	Post = "POST",
}

export type AllOrders = {
	__typename?: "AllOrders";
	edges?: Maybe<Array<OrderWithFridge>>;
	pageInfo?: Maybe<AllOrdersPageInfo>;
};

export type AllOrdersPageInfo = {
	__typename?: "AllOrdersPageInfo";
	endCursor?: Maybe<Scalars["ID"]["output"]>;
	hasNextPage?: Maybe<Scalars["Boolean"]["output"]>;
};

export enum AllergenType {
	Celery = "CELERY",
	Crustaceans = "CRUSTACEANS",
	Eggs = "EGGS",
	Fish = "FISH",
	Gluten = "GLUTEN",
	Lupin = "LUPIN",
	Milk = "MILK",
	Molluscs = "MOLLUSCS",
	Mustard = "MUSTARD",
	Nuts = "NUTS",
	Peanuts = "PEANUTS",
	SesameSeeds = "SESAME_SEEDS",
	Soybeans = "SOYBEANS",
	Spicy = "SPICY",
	SulphurDioxide = "SULPHUR_DIOXIDE",
}

export type ApplePayOrderUpdate = {
	orderId: Scalars["ID"]["input"];
	token: Scalars["String"]["input"];
};

export enum ApplePayPaymentResult {
	Accepted = "ACCEPTED",
	Rejected = "REJECTED",
}

export type ApplePayResult = {
	__typename?: "ApplePayResult";
	error?: Maybe<Error>;
	/** @deprecated Duplicate of Error.PAYMENT_REJECTED / Empty result */
	result?: Maybe<ApplePayPaymentResult>;
};

export type AppleUser = {
	email?: InputMaybe<Scalars["String"]["input"]>;
	name?: InputMaybe<AppleUserName>;
};

export type AppleUserName = {
	first?: InputMaybe<Scalars["String"]["input"]>;
	last?: InputMaybe<Scalars["String"]["input"]>;
};

export enum CurrencyType {
	Czk = "CZK",
	Eur = "EUR",
}

export type DeviceTokenInput = {
	token: Scalars["String"]["input"];
};

export type DeviceTokenResponse = {
	__typename?: "DeviceTokenResponse";
	error?: Maybe<Error>;
};

export type Dish = {
	__typename?: "Dish";
	expiration?: Maybe<Scalars["String"]["output"]>;
	id: Scalars["ID"]["output"];
	position?: Maybe<DishSegmentType>;
	price?: Maybe<Price>;
	type?: Maybe<DishType>;
};

export type DishSegment = {
	__typename?: "DishSegment";
	capacity?: Maybe<Scalars["Int"]["output"]>;
	type?: Maybe<DishSegmentType>;
};

export type DishSegmentSubscription = {
	__typename?: "DishSegmentSubscription";
	capacity?: Maybe<Scalars["Int"]["output"]>;
	type?: Maybe<DishSegmentType>;
};

export type DishSegmentSubscriptionUpdate = {
	capacity?: InputMaybe<Scalars["Int"]["input"]>;
	type?: InputMaybe<DishSegmentType>;
};

export enum DishSegmentType {
	Box_1_1 = "BOX_1_1",
	Box_1_2 = "BOX_1_2",
	Box_1_3 = "BOX_1_3",
	Box_2_1 = "BOX_2_1",
	Box_2_2 = "BOX_2_2",
	Box_2_3 = "BOX_2_3",
	Box_3_1 = "BOX_3_1",
	Box_3_2 = "BOX_3_2",
	Box_3_3 = "BOX_3_3",
	Box_4_1 = "BOX_4_1",
	Box_4_2 = "BOX_4_2",
	Box_4_3 = "BOX_4_3",
	Box_5_1 = "BOX_5_1",
	Box_5_2 = "BOX_5_2",
	Box_5_3 = "BOX_5_3",
	Box_6_1 = "BOX_6_1",
	Box_6_2 = "BOX_6_2",
	Box_6_3 = "BOX_6_3",
}

export type DishSegmentUpdate = {
	capacity?: InputMaybe<Scalars["Int"]["input"]>;
	type?: InputMaybe<DishSegmentType>;
};

export type DishSubscription = {
	__typename?: "DishSubscription";
	expiration?: Maybe<Scalars["String"]["output"]>;
	id: Scalars["ID"]["output"];
	position?: Maybe<DishSegmentType>;
	price?: Maybe<PriceSubscription>;
	type?: Maybe<DishTypeSubscription>;
};

export type DishSubscriptionUpdate = {
	expiration?: InputMaybe<Scalars["String"]["input"]>;
	id: Scalars["ID"]["input"];
	position?: InputMaybe<DishSegmentType>;
	price?: InputMaybe<PriceSubscriptionUpdate>;
	type?: InputMaybe<DishTypeSubscriptionUpdate>;
};

export type DishType = {
	__typename?: "DishType";
	allergens?: Maybe<Array<AllergenType>>;
	description?: Maybe<DishTypeDescription>;
	dishes?: Maybe<Array<Dish>>;
	id: Scalars["ID"]["output"];
	image?: Maybe<Image>;
	lighting?: Maybe<DishTypeLighting>;
	price?: Maybe<Price>;
	weight?: Maybe<Scalars["String"]["output"]>;
};

export type DishTypeDescription = {
	__typename?: "DishTypeDescription";
	primary?: Maybe<DishTypeDescriptionDetail>;
	secondary?: Maybe<DishTypeDescriptionDetail>;
};

export type DishTypeDescriptionDetail = {
	__typename?: "DishTypeDescriptionDetail";
	composition?: Maybe<Scalars["String"]["output"]>;
	description?: Maybe<Scalars["String"]["output"]>;
	name?: Maybe<Scalars["String"]["output"]>;
};

export type DishTypeDescriptionDetailSubscription = {
	__typename?: "DishTypeDescriptionDetailSubscription";
	composition?: Maybe<Scalars["String"]["output"]>;
	description?: Maybe<Scalars["String"]["output"]>;
	name?: Maybe<Scalars["String"]["output"]>;
};

export type DishTypeDescriptionDetailSubscriptionUpdate = {
	composition?: InputMaybe<Scalars["String"]["input"]>;
	description?: InputMaybe<Scalars["String"]["input"]>;
	name?: InputMaybe<Scalars["String"]["input"]>;
};

export type DishTypeDescriptionSubscription = {
	__typename?: "DishTypeDescriptionSubscription";
	primary?: Maybe<DishTypeDescriptionDetailSubscription>;
	secondary?: Maybe<DishTypeDescriptionDetailSubscription>;
};

export type DishTypeDescriptionSubscriptionUpdate = {
	primary?: InputMaybe<DishTypeDescriptionDetailSubscriptionUpdate>;
	secondary?: InputMaybe<DishTypeDescriptionDetailSubscriptionUpdate>;
};

export type DishTypeLighting = {
	__typename?: "DishTypeLighting";
	mode?: Maybe<LightingSegmentModeType>;
};

export type DishTypeLightingSubscription = {
	__typename?: "DishTypeLightingSubscription";
	mode?: Maybe<LightingSegmentModeType>;
};

export type DishTypeLightingSubscriptionUpdate = {
	mode?: InputMaybe<LightingSegmentModeType>;
};

export type DishTypeLightingUpdate = {
	mode?: InputMaybe<LightingSegmentModeType>;
};

export type DishTypeSubscription = {
	__typename?: "DishTypeSubscription";
	allergens?: Maybe<Array<AllergenType>>;
	description?: Maybe<DishTypeDescriptionSubscription>;
	dishes?: Maybe<Array<DishSubscription>>;
	id: Scalars["ID"]["output"];
	image?: Maybe<ImageSubscription>;
	lighting?: Maybe<DishTypeLightingSubscription>;
	price?: Maybe<PriceSubscription>;
	weight?: Maybe<Scalars["String"]["output"]>;
};

export type DishTypeSubscriptionUpdate = {
	allergens?: InputMaybe<Array<AllergenType>>;
	description?: InputMaybe<DishTypeDescriptionSubscriptionUpdate>;
	dishes?: InputMaybe<Array<DishSubscriptionUpdate>>;
	id: Scalars["ID"]["input"];
	image?: InputMaybe<ImageSubscriptionUpdate>;
	lighting?: InputMaybe<DishTypeLightingSubscriptionUpdate>;
	price?: InputMaybe<PriceSubscriptionUpdate>;
	weight?: InputMaybe<Scalars["String"]["input"]>;
};

export type DishTypeUpdate = {
	id: Scalars["ID"]["input"];
	lighting?: InputMaybe<DishTypeLightingUpdate>;
};

export enum DoorState {
	Closed = "CLOSED",
	Opened = "OPENED",
}

export type Error = {
	__typename?: "Error";
	message?: Maybe<Scalars["String"]["output"]>;
	type: ErrorType;
};

export type ErrorSubscription = {
	__typename?: "ErrorSubscription";
	message?: Maybe<Scalars["String"]["output"]>;
	type: ErrorType;
};

export type ErrorSubscriptionUpdate = {
	message?: InputMaybe<Scalars["String"]["input"]>;
	type: ErrorType;
};

export enum ErrorType {
	BadRequest = "BAD_REQUEST",
	Conflict = "CONFLICT",
	Forbidden = "FORBIDDEN",
	InternalError = "INTERNAL_ERROR",
	NotImplemented = "NOT_IMPLEMENTED",
	PaymentRejected = "PAYMENT_REJECTED",
	Unauthorized = "UNAUTHORIZED",
}

export type Fridge = FridgeBase & {
	__typename?: "Fridge";
	access?: Maybe<FridgeAccess>;
	dishes?: Maybe<FridgeDishes>;
	door?: Maybe<FridgeDoor>;
	error?: Maybe<Error>;
	id: Scalars["ID"]["output"];
	lighting?: Maybe<Lighting>;
	name?: Maybe<Scalars["String"]["output"]>;
	segments?: Maybe<Array<FridgeSegment>>;
	transaction?: Maybe<FridgeTransaction>;
};

export type FridgeAccess = {
	__typename?: "FridgeAccess";
	qrCode?: Maybe<QrCode>;
};

export type FridgeAccessSubscription = {
	__typename?: "FridgeAccessSubscription";
	qrCode?: Maybe<QrCodeSubscription>;
};

export type FridgeAccessSubscriptionUpdate = {
	qrCode?: InputMaybe<QrCodeSubscriptionUpdate>;
};

export type FridgeBase = {
	id: Scalars["ID"]["output"];
	name?: Maybe<Scalars["String"]["output"]>;
};

export type FridgeDishes = {
	__typename?: "FridgeDishes";
	items?: Maybe<Array<Dish>>;
	types?: Maybe<Array<DishType>>;
};

export type FridgeDishesSubscription = {
	__typename?: "FridgeDishesSubscription";
	types?: Maybe<Array<DishTypeSubscription>>;
};

export type FridgeDishesSubscriptionUpdate = {
	types?: InputMaybe<Array<DishTypeSubscriptionUpdate>>;
};

export type FridgeDishesUpdate = {
	types?: InputMaybe<Array<DishTypeUpdate>>;
};

export type FridgeDoor = {
	__typename?: "FridgeDoor";
	lock?: Maybe<FridgeDoorLock>;
	state?: Maybe<DoorState>;
};

export type FridgeDoorLock = {
	__typename?: "FridgeDoorLock";
	error?: Maybe<Error>;
	state?: Maybe<LockState>;
	timeout?: Maybe<Scalars["Int"]["output"]>;
};

export type FridgeDoorLockSubscription = {
	__typename?: "FridgeDoorLockSubscription";
	error?: Maybe<ErrorSubscription>;
	state?: Maybe<LockState>;
	timeout?: Maybe<Scalars["Int"]["output"]>;
};

export type FridgeDoorLockSubscriptionUpdate = {
	error?: InputMaybe<ErrorSubscriptionUpdate>;
	state?: InputMaybe<LockState>;
	timeout?: InputMaybe<Scalars["Int"]["input"]>;
};

export type FridgeDoorSubscription = {
	__typename?: "FridgeDoorSubscription";
	lock?: Maybe<FridgeDoorLockSubscription>;
	state?: Maybe<DoorState>;
};

export type FridgeDoorSubscriptionUpdate = {
	lock?: InputMaybe<FridgeDoorLockSubscriptionUpdate>;
	state?: InputMaybe<DoorState>;
};

export type FridgeSegment = {
	__typename?: "FridgeSegment";
	light?: Maybe<SegmentLight>;
	segment?: Maybe<DishSegment>;
};

export type FridgeSegmentSubscription = {
	__typename?: "FridgeSegmentSubscription";
	light?: Maybe<SegmentLightSubscription>;
	segment?: Maybe<DishSegmentSubscription>;
};

export type FridgeSegmentSubscriptionUpdate = {
	light?: InputMaybe<SegmentLightSubscriptionUpdate>;
	segment?: InputMaybe<DishSegmentSubscriptionUpdate>;
};

export type FridgeSegmentUpdate = {
	light?: InputMaybe<SegmentLightUpdate>;
	segment?: InputMaybe<DishSegmentUpdate>;
};

export type FridgeSubscription = {
	__typename?: "FridgeSubscription";
	access?: Maybe<FridgeAccessSubscription>;
	dishes?: Maybe<FridgeDishesSubscription>;
	door?: Maybe<FridgeDoorSubscription>;
	error?: Maybe<ErrorSubscription>;
	id: Scalars["ID"]["output"];
	lighting?: Maybe<LightingSubscription>;
	name?: Maybe<Scalars["String"]["output"]>;
	segments?: Maybe<Array<FridgeSegmentSubscription>>;
	transaction?: Maybe<FridgeTransactionSubscription>;
};

export type FridgeSubscriptionUpdate = {
	access?: InputMaybe<FridgeAccessSubscriptionUpdate>;
	dishes?: InputMaybe<FridgeDishesSubscriptionUpdate>;
	door?: InputMaybe<FridgeDoorSubscriptionUpdate>;
	error?: InputMaybe<ErrorSubscriptionUpdate>;
	id: Scalars["ID"]["input"];
	lighting?: InputMaybe<LightingSubscriptionUpdate>;
	name?: InputMaybe<Scalars["String"]["input"]>;
	segments?: InputMaybe<Array<FridgeSegmentSubscriptionUpdate>>;
	transaction?: InputMaybe<FridgeTransactionSubscriptionUpdate>;
};

export type FridgeTransaction = {
	__typename?: "FridgeTransaction";
	order?: Maybe<Order>;
	orders?: Maybe<Orders>;
	refills?: Maybe<Refills>;
};

export type FridgeTransactionOrderArgs = {
	orderId?: InputMaybe<Scalars["ID"]["input"]>;
};

export type FridgeTransactionSubscription = {
	__typename?: "FridgeTransactionSubscription";
	order?: Maybe<OrderSubscription>;
	orders?: Maybe<OrdersSubscription>;
	refills?: Maybe<RefillsSubscription>;
};

export type FridgeTransactionSubscriptionUpdate = {
	order?: InputMaybe<OrderSubscriptionUpdate>;
	orders?: InputMaybe<OrdersSubscriptionUpdate>;
	refills?: InputMaybe<RefillsSubscriptionUpdate>;
};

export type FridgeTransactionUpdate = {
	orders?: InputMaybe<OrdersUpdate>;
};

export type FridgeUpdate = {
	dishes?: InputMaybe<FridgeDishesUpdate>;
	id: Scalars["ID"]["input"];
	lighting?: InputMaybe<LightingUpdate>;
	segments?: InputMaybe<Array<FridgeSegmentUpdate>>;
	transaction?: InputMaybe<FridgeTransactionUpdate>;
};

export type GooglePayMetaUpdate = {
	browser?: InputMaybe<PaymentBrowserUpdate>;
};

export type GooglePayOrderUpdate = {
	meta?: InputMaybe<GooglePayMetaUpdate>;
	orderId: Scalars["ID"]["input"];
	token: Scalars["String"]["input"];
};

export enum GooglePayPaymentResult {
	Accepted = "ACCEPTED",
	Rejected = "REJECTED",
}

export type GooglePayResult = {
	__typename?: "GooglePayResult";
	error?: Maybe<Error>;
	redirect?: Maybe<AdyenPaymentRedirect>;
	/** @deprecated Duplicate of Error.PAYMENT_REJECTED / Empty result */
	result?: Maybe<GooglePayPaymentResult>;
};

export type Image = {
	__typename?: "Image";
	url?: Maybe<Scalars["String"]["output"]>;
};

export type ImageSubscription = {
	__typename?: "ImageSubscription";
	url?: Maybe<Scalars["String"]["output"]>;
};

export type ImageSubscriptionUpdate = {
	url?: InputMaybe<Scalars["String"]["input"]>;
};

export type LedLight = {
	__typename?: "LedLight";
	color?: Maybe<LightColor>;
	position?: Maybe<Scalars["Int"]["output"]>;
};

export type LedLightSubscription = {
	__typename?: "LedLightSubscription";
	color?: Maybe<LightColorSubscription>;
	position?: Maybe<Scalars["Int"]["output"]>;
};

export type LedLightSubscriptionUpdate = {
	color?: InputMaybe<LightColorSubscriptionUpdate>;
	position?: InputMaybe<Scalars["Int"]["input"]>;
};

export type LedLightUpdate = {
	color?: InputMaybe<LightColorUpdate>;
	position?: InputMaybe<Scalars["Int"]["input"]>;
};

export type LightColor = {
	__typename?: "LightColor";
	b?: Maybe<Scalars["Int"]["output"]>;
	g?: Maybe<Scalars["Int"]["output"]>;
	r?: Maybe<Scalars["Int"]["output"]>;
	w?: Maybe<Scalars["Int"]["output"]>;
};

export type LightColorSubscription = {
	__typename?: "LightColorSubscription";
	b?: Maybe<Scalars["Int"]["output"]>;
	g?: Maybe<Scalars["Int"]["output"]>;
	r?: Maybe<Scalars["Int"]["output"]>;
	w?: Maybe<Scalars["Int"]["output"]>;
};

export type LightColorSubscriptionUpdate = {
	b?: InputMaybe<Scalars["Int"]["input"]>;
	g?: InputMaybe<Scalars["Int"]["input"]>;
	r?: InputMaybe<Scalars["Int"]["input"]>;
	w?: InputMaybe<Scalars["Int"]["input"]>;
};

export type LightColorUpdate = {
	b?: InputMaybe<Scalars["Int"]["input"]>;
	g?: InputMaybe<Scalars["Int"]["input"]>;
	r?: InputMaybe<Scalars["Int"]["input"]>;
	w?: InputMaybe<Scalars["Int"]["input"]>;
};

export type Lighting = {
	__typename?: "Lighting";
	mode?: Maybe<LightingModeType>;
};

export enum LightingModeType {
	Active = "ACTIVE",
	StandBy = "STAND_BY",
}

export enum LightingSegmentModeType {
	Default = "DEFAULT",
	Spotlight = "SPOTLIGHT",
}

export type LightingSubscription = {
	__typename?: "LightingSubscription";
	mode?: Maybe<LightingModeType>;
};

export type LightingSubscriptionUpdate = {
	mode?: InputMaybe<LightingModeType>;
};

export type LightingUpdate = {
	mode?: InputMaybe<LightingModeType>;
};

export enum LockState {
	Locked = "LOCKED",
	Unlocked = "UNLOCKED",
}

export type Mutation = {
	__typename?: "Mutation";
	createUserAccountApple: UserAccountSecret;
	/** @deprecated This mutation is deprecated. Use createUserProfileLink */
	createUserAccountCode: UserAccountSecret;
	createUserAccountGoogle: UserAccountSecret;
	/** @deprecated This mutation is deprecated. Use createUserProfileLink */
	createUserAccountSecret: UserAccountSecret;
	createUserProfileLink: UserProfileLinkResponse;
	loginWithSecret: UserLogin;
	payOrderWithApplePay: ApplePayResult;
	payOrderWithGooglePay: GooglePayResult;
	registerDeviceToken: DeviceTokenResponse;
	unpairUserAccount: UserAccountUnpair;
	unpairUserAccountApple: UserAccountUnpair;
	unpairUserAccountGoogle: UserAccountUnpair;
	unregisterDeviceToken: DeviceTokenResponse;
	updateCurrentUser: User;
	updateCurrentUserSubscription: UserSubscription;
	updateFridge: Fridge;
	updateFridgeSubscription: FridgeSubscription;
};

export type MutationCreateUserAccountAppleArgs = {
	input: UserAppleLoginInput;
};

export type MutationCreateUserAccountCodeArgs = {
	input: Scalars["String"]["input"];
};

export type MutationCreateUserAccountGoogleArgs = {
	input: UserGoogleLoginInput;
};

export type MutationCreateUserAccountSecretArgs = {
	input: Scalars["String"]["input"];
};

export type MutationCreateUserProfileLinkArgs = {
	input: UserAccountLinkInput;
};

export type MutationLoginWithSecretArgs = {
	input: UserSecretLoginInput;
};

export type MutationPayOrderWithApplePayArgs = {
	input: ApplePayOrderUpdate;
};

export type MutationPayOrderWithGooglePayArgs = {
	input: GooglePayOrderUpdate;
};

export type MutationRegisterDeviceTokenArgs = {
	input: DeviceTokenInput;
};

export type MutationUnregisterDeviceTokenArgs = {
	input: DeviceTokenInput;
};

export type MutationUpdateCurrentUserArgs = {
	input: UserUpdate;
};

export type MutationUpdateCurrentUserSubscriptionArgs = {
	input: UserSubscriptionUpdate;
};

export type MutationUpdateFridgeArgs = {
	input: FridgeUpdate;
};

export type MutationUpdateFridgeSubscriptionArgs = {
	input: FridgeSubscriptionUpdate;
};

export type Order = OrderBase &
	Transaction & {
		__typename?: "Order";
		access?: Maybe<TransactionAccess>;
		end?: Maybe<Scalars["String"]["output"]>;
		feedback?: Maybe<OrderFeedback>;
		fridge?: Maybe<Fridge>;
		id: Scalars["ID"]["output"];
		inventory?: Maybe<TransactionInventory>;
		payment?: Maybe<Payment>;
		price?: Maybe<OrderPrice>;
		result?: Maybe<TransactionResult>;
		start?: Maybe<Scalars["String"]["output"]>;
		state?: Maybe<TransactionState>;
		type?: Maybe<TransactionType>;
		user?: Maybe<User>;
	};

export type OrderBase = {
	end?: Maybe<Scalars["String"]["output"]>;
	id: Scalars["ID"]["output"];
	payment?: Maybe<Payment>;
	price?: Maybe<OrderPrice>;
	start?: Maybe<Scalars["String"]["output"]>;
};

export type OrderEmptyFeedback = {
	__typename?: "OrderEmptyFeedback";
	comment?: Maybe<Scalars["String"]["output"]>;
	reason?: Maybe<OrderEmptyFeedbackReasonType>;
};

export enum OrderEmptyFeedbackReasonType {
	Other = "OTHER",
}

export type OrderEmptyFeedbackUpdate = {
	comment?: InputMaybe<Scalars["String"]["input"]>;
	reason?: InputMaybe<OrderEmptyFeedbackReasonType>;
};

export type OrderFeedback = {
	__typename?: "OrderFeedback";
	empty?: Maybe<OrderEmptyFeedback>;
	error?: Maybe<Error>;
	finished?: Maybe<OrderFinishedFeedback>;
};

export type OrderFeedbackUpdate = {
	empty?: InputMaybe<OrderEmptyFeedbackUpdate>;
	finished?: InputMaybe<OrderFinishedFeedbackUpdate>;
};

export type OrderFinishedFeedback = {
	__typename?: "OrderFinishedFeedback";
	rating?: Maybe<OrderFinishedFeedbackRatingType>;
};

export enum OrderFinishedFeedbackRatingType {
	Fair = "FAIR",
	Good = "GOOD",
	Poor = "POOR",
	VeryGood = "VERY_GOOD",
}

export type OrderFinishedFeedbackUpdate = {
	rating?: InputMaybe<OrderFinishedFeedbackRatingType>;
};

export type OrderPaymentMethodUpdate = {
	id: Scalars["ID"]["input"];
};

export type OrderPaymentUpdate = {
	method?: InputMaybe<OrderPaymentMethodUpdate>;
};

export type OrderPrice = {
	__typename?: "OrderPrice";
	total?: Maybe<Price>;
};

export type OrderPriceSubscription = {
	__typename?: "OrderPriceSubscription";
	total?: Maybe<PriceSubscription>;
};

export type OrderPriceSubscriptionUpdate = {
	total?: InputMaybe<PriceSubscriptionUpdate>;
};

export type OrderSubscription = {
	__typename?: "OrderSubscription";
	access?: Maybe<TransactionAccessSubscription>;
	end?: Maybe<Scalars["String"]["output"]>;
	fridge?: Maybe<FridgeSubscription>;
	id: Scalars["ID"]["output"];
	inventory?: Maybe<TransactionInventorySubscription>;
	payment?: Maybe<PaymentSubscription>;
	price?: Maybe<OrderPriceSubscription>;
	result?: Maybe<TransactionResult>;
	start?: Maybe<Scalars["String"]["output"]>;
	state?: Maybe<TransactionState>;
	type?: Maybe<TransactionType>;
	user?: Maybe<UserSubscription>;
};

export type OrderSubscriptionUpdate = {
	access?: InputMaybe<TransactionAccessSubscriptionUpdate>;
	end?: InputMaybe<Scalars["String"]["input"]>;
	fridge?: InputMaybe<FridgeSubscriptionUpdate>;
	id: Scalars["ID"]["input"];
	inventory?: InputMaybe<TransactionInventorySubscriptionUpdate>;
	payment?: InputMaybe<PaymentSubscriptionUpdate>;
	price?: InputMaybe<OrderPriceSubscriptionUpdate>;
	result?: InputMaybe<TransactionResult>;
	start?: InputMaybe<Scalars["String"]["input"]>;
	state?: InputMaybe<TransactionState>;
	type?: InputMaybe<TransactionType>;
	user?: InputMaybe<UserSubscriptionUpdate>;
};

export type OrderUpdate = {
	feedback?: InputMaybe<OrderFeedbackUpdate>;
	id: Scalars["ID"]["input"];
	payment?: InputMaybe<OrderPaymentUpdate>;
};

export type OrderWithFridge = OrderBase & {
	__typename?: "OrderWithFridge";
	end?: Maybe<Scalars["String"]["output"]>;
	fridge?: Maybe<FridgeBase>;
	id: Scalars["ID"]["output"];
	inventory?: Maybe<TransactionInventoryBase>;
	payment?: Maybe<Payment>;
	price?: Maybe<OrderPrice>;
	start?: Maybe<Scalars["String"]["output"]>;
};

export type Orders = {
	__typename?: "Orders";
	all?: Maybe<AllOrders>;
	last?: Maybe<Order>;
};

export type OrdersAllArgs = {
	after?: InputMaybe<Scalars["ID"]["input"]>;
	first?: InputMaybe<Scalars["Int"]["input"]>;
};

export type OrdersSubscription = {
	__typename?: "OrdersSubscription";
	last?: Maybe<OrderSubscription>;
};

export type OrdersSubscriptionUpdate = {
	last?: InputMaybe<OrderSubscriptionUpdate>;
};

export type OrdersUpdate = {
	last: OrderUpdate;
};

export type Payment = {
	__typename?: "Payment";
	error?: Maybe<Error>;
	method?: Maybe<PaymentMethod>;
	state?: Maybe<PaymentState>;
	/** @deprecated Use method.type instead */
	type?: Maybe<PaymentType>;
};

export type PaymentBrowserUpdate = {
	accept?: InputMaybe<Scalars["String"]["input"]>;
	userAgent?: InputMaybe<Scalars["String"]["input"]>;
};

export type PaymentMethod = {
	__typename?: "PaymentMethod";
	error?: Maybe<Error>;
	id: Scalars["ID"]["output"];
	state?: Maybe<PaymentMethodState>;
	type?: Maybe<PaymentMethodType>;
};

export enum PaymentMethodState {
	Disabled = "DISABLED",
	Enabled = "ENABLED",
}

export type PaymentMethodSubscription = {
	__typename?: "PaymentMethodSubscription";
	id: Scalars["ID"]["output"];
	state?: Maybe<PaymentMethodState>;
	type?: Maybe<PaymentMethodType>;
};

export type PaymentMethodSubscriptionUpdate = {
	id: Scalars["ID"]["input"];
	state?: InputMaybe<PaymentMethodState>;
	type?: InputMaybe<PaymentMethodType>;
};

export enum PaymentMethodType {
	CreditCard = "CREDIT_CARD",
	EmployeeCard = "EMPLOYEE_CARD",
	NativePay = "NATIVE_PAY",
	NativePayTopUp = "NATIVE_PAY_TOP_UP",
	PaymentCardTopUp = "PAYMENT_CARD_TOP_UP",
}

export type PaymentMethodUpdate = {
	id: Scalars["ID"]["input"];
	state?: InputMaybe<PaymentMethodState>;
};

export enum PaymentState {
	Failed = "FAILED",
	Paid = "PAID",
	Pending = "PENDING",
	Processing = "PROCESSING",
}

export type PaymentSubscription = {
	__typename?: "PaymentSubscription";
	method?: Maybe<PaymentMethodSubscription>;
	state?: Maybe<PaymentState>;
	/** @deprecated Use method.type instead */
	type?: Maybe<PaymentType>;
};

export type PaymentSubscriptionUpdate = {
	method?: InputMaybe<PaymentMethodSubscriptionUpdate>;
	state?: InputMaybe<PaymentState>;
};

export enum PaymentType {
	ApplePay = "APPLE_PAY",
	EmployeeCard = "EMPLOYEE_CARD",
	GooglePay = "GOOGLE_PAY",
}

export type PaymentUpdate = {
	method?: InputMaybe<PaymentMethodUpdate>;
};

export type Price = {
	__typename?: "Price";
	currency?: Maybe<CurrencyType>;
	current?: Maybe<Scalars["String"]["output"]>;
	default?: Maybe<Scalars["String"]["output"]>;
};

export type PriceSubscription = {
	__typename?: "PriceSubscription";
	currency?: Maybe<CurrencyType>;
	current?: Maybe<Scalars["String"]["output"]>;
	default?: Maybe<Scalars["String"]["output"]>;
};

export type PriceSubscriptionUpdate = {
	currency?: InputMaybe<CurrencyType>;
	current?: InputMaybe<Scalars["String"]["input"]>;
	default?: InputMaybe<Scalars["String"]["input"]>;
};

export type PriceUpdate = {
	currency?: InputMaybe<CurrencyType>;
	current?: InputMaybe<Scalars["String"]["input"]>;
	default?: InputMaybe<Scalars["String"]["input"]>;
};

export type Profile = {
	email?: Maybe<Scalars["String"]["output"]>;
	id: Scalars["ID"]["output"];
	name?: Maybe<Scalars["String"]["output"]>;
	phone?: Maybe<Scalars["String"]["output"]>;
};

export type QrCode = {
	__typename?: "QrCode";
	code?: Maybe<Scalars["String"]["output"]>;
};

export type QrCodeSubscription = {
	__typename?: "QrCodeSubscription";
	code?: Maybe<Scalars["String"]["output"]>;
};

export type QrCodeSubscriptionUpdate = {
	code?: InputMaybe<Scalars["String"]["input"]>;
};

export type Query = {
	__typename?: "Query";
	currentUser: User;
	fridge: Fridge;
};

export type QueryFridgeArgs = {
	id: Scalars["ID"]["input"];
};

export type Refill = Transaction & {
	__typename?: "Refill";
	access?: Maybe<TransactionAccess>;
	end?: Maybe<Scalars["String"]["output"]>;
	fridge?: Maybe<Fridge>;
	id: Scalars["ID"]["output"];
	inventory?: Maybe<TransactionInventory>;
	result?: Maybe<TransactionResult>;
	start?: Maybe<Scalars["String"]["output"]>;
	state?: Maybe<TransactionState>;
	type?: Maybe<TransactionType>;
	user?: Maybe<User>;
};

export type RefillSubscription = {
	__typename?: "RefillSubscription";
	access?: Maybe<TransactionAccessSubscription>;
	end?: Maybe<Scalars["String"]["output"]>;
	fridge?: Maybe<FridgeSubscription>;
	id: Scalars["ID"]["output"];
	inventory?: Maybe<TransactionInventorySubscription>;
	result?: Maybe<TransactionResult>;
	start?: Maybe<Scalars["String"]["output"]>;
	state?: Maybe<TransactionState>;
	type?: Maybe<TransactionType>;
	user?: Maybe<UserSubscription>;
};

export type RefillSubscriptionUpdate = {
	access?: InputMaybe<TransactionAccessSubscriptionUpdate>;
	end?: InputMaybe<Scalars["String"]["input"]>;
	fridge?: InputMaybe<FridgeSubscriptionUpdate>;
	id: Scalars["ID"]["input"];
	inventory?: InputMaybe<TransactionInventorySubscriptionUpdate>;
	result?: InputMaybe<TransactionResult>;
	start?: InputMaybe<Scalars["String"]["input"]>;
	state?: InputMaybe<TransactionState>;
	type?: InputMaybe<TransactionType>;
	user?: InputMaybe<UserSubscriptionUpdate>;
};

export type Refills = {
	__typename?: "Refills";
	all?: Maybe<Array<Refill>>;
	last?: Maybe<Refill>;
};

export type RefillsSubscription = {
	__typename?: "RefillsSubscription";
	last?: Maybe<RefillSubscription>;
};

export type RefillsSubscriptionUpdate = {
	last?: InputMaybe<RefillSubscriptionUpdate>;
};

export type SegmentLedLights = {
	__typename?: "SegmentLedLights";
	all?: Maybe<LightColor>;
	leds?: Maybe<Array<LedLight>>;
};

export type SegmentLedLightsSubscription = {
	__typename?: "SegmentLedLightsSubscription";
	all?: Maybe<LightColorSubscription>;
	leds?: Maybe<Array<LedLightSubscription>>;
};

export type SegmentLedLightsSubscriptionUpdate = {
	all?: InputMaybe<LightColorSubscriptionUpdate>;
	leds?: InputMaybe<Array<LedLightSubscriptionUpdate>>;
};

export type SegmentLedLightsUpdate = {
	all?: InputMaybe<LightColorUpdate>;
	leds?: InputMaybe<Array<LedLightUpdate>>;
};

export type SegmentLight = {
	__typename?: "SegmentLight";
	ambient?: Maybe<SegmentLedLights>;
	front?: Maybe<SegmentLedLights>;
};

export type SegmentLightSubscription = {
	__typename?: "SegmentLightSubscription";
	ambient?: Maybe<SegmentLedLightsSubscription>;
	front?: Maybe<SegmentLedLightsSubscription>;
};

export type SegmentLightSubscriptionUpdate = {
	ambient?: InputMaybe<SegmentLedLightsSubscriptionUpdate>;
	front?: InputMaybe<SegmentLedLightsSubscriptionUpdate>;
};

export type SegmentLightUpdate = {
	ambient?: InputMaybe<SegmentLedLightsUpdate>;
	front?: InputMaybe<SegmentLedLightsUpdate>;
};

export enum SortType {
	Ascending = "ASCENDING",
	Descending = "DESCENDING",
}

export type Subscription = {
	__typename?: "Subscription";
	currentUser?: Maybe<UserSubscription>;
	fridge?: Maybe<FridgeSubscription>;
};

export type SubscriptionCurrentUserArgs = {
	id: Scalars["ID"]["input"];
};

export type SubscriptionFridgeArgs = {
	id: Scalars["ID"]["input"];
};

export type Transaction = {
	access?: Maybe<TransactionAccess>;
	end?: Maybe<Scalars["String"]["output"]>;
	fridge?: Maybe<Fridge>;
	id: Scalars["ID"]["output"];
	inventory?: Maybe<TransactionInventory>;
	result?: Maybe<TransactionResult>;
	start?: Maybe<Scalars["String"]["output"]>;
	state?: Maybe<TransactionState>;
	type?: Maybe<TransactionType>;
	user?: Maybe<User>;
};

export type TransactionAccess = {
	__typename?: "TransactionAccess";
	type?: Maybe<TransactionAccessType>;
};

export type TransactionAccessSubscription = {
	__typename?: "TransactionAccessSubscription";
	type?: Maybe<TransactionAccessType>;
};

export type TransactionAccessSubscriptionUpdate = {
	type?: InputMaybe<TransactionAccessType>;
};

export enum TransactionAccessType {
	Card = "CARD",
	QrCode = "QR_CODE",
}

export type TransactionInventory = {
	__typename?: "TransactionInventory";
	added?: Maybe<Array<Dish>>;
	removed?: Maybe<Array<Dish>>;
	updated?: Maybe<Array<Dish>>;
};

export type TransactionInventoryBase = {
	__typename?: "TransactionInventoryBase";
	added?: Maybe<Scalars["Int"]["output"]>;
	removed?: Maybe<Scalars["Int"]["output"]>;
};

export type TransactionInventorySubscription = {
	__typename?: "TransactionInventorySubscription";
	added?: Maybe<Array<DishSubscription>>;
	removed?: Maybe<Array<DishSubscription>>;
	updated?: Maybe<Array<DishSubscription>>;
};

export type TransactionInventorySubscriptionUpdate = {
	added?: InputMaybe<Array<DishSubscriptionUpdate>>;
	removed?: InputMaybe<Array<DishSubscriptionUpdate>>;
	updated?: InputMaybe<Array<DishSubscriptionUpdate>>;
};

export enum TransactionResult {
	Error = "ERROR",
	Success = "SUCCESS",
	Timeout = "TIMEOUT",
	Unauthorized = "UNAUTHORIZED",
}

export enum TransactionState {
	Active = "ACTIVE",
	Finished = "FINISHED",
	StockCheck = "STOCK_CHECK",
}

export enum TransactionType {
	Order = "ORDER",
	Refill = "REFILL",
}

export type User = Profile & {
	__typename?: "User";
	email?: Maybe<Scalars["String"]["output"]>;
	/** @deprecated Use email instead. */
	emails?: Maybe<Array<Scalars["String"]["output"]>>;
	error?: Maybe<Error>;
	id: Scalars["ID"]["output"];
	name?: Maybe<Scalars["String"]["output"]>;
	/** @deprecated Use transaction.orders.all instead. */
	orders?: Maybe<Array<Order>>;
	payment?: Maybe<UserPayment>;
	phone?: Maybe<Scalars["String"]["output"]>;
	/** @deprecated Use phone instead. */
	phones?: Maybe<Array<Scalars["String"]["output"]>>;
	profiles?: Maybe<Array<UserProfile>>;
	transaction?: Maybe<FridgeTransaction>;
};

export type UserAccountLinkInput = {
	code: Scalars["String"]["input"];
};

export type UserAccountSecret = {
	__typename?: "UserAccountSecret";
	error?: Maybe<Error>;
	id?: Maybe<Scalars["String"]["output"]>;
	secret?: Maybe<Scalars["String"]["output"]>;
};

export type UserAccountUnpair = {
	__typename?: "UserAccountUnpair";
	error?: Maybe<Error>;
};

export type UserAppleLoginInput = {
	authorizationCode: Scalars["String"]["input"];
	idToken: Scalars["String"]["input"];
	user?: InputMaybe<AppleUser>;
};

export type UserGoogleLoginInput = {
	idToken: Scalars["String"]["input"];
};

export type UserLogin = {
	__typename?: "UserLogin";
	error?: Maybe<Error>;
	session?: Maybe<Scalars["String"]["output"]>;
	validTo?: Maybe<Scalars["String"]["output"]>;
};

export type UserPayment = {
	__typename?: "UserPayment";
	default?: Maybe<PaymentMethod>;
	error?: Maybe<Error>;
	options?: Maybe<Array<PaymentMethod>>;
};

export type UserPaymentSubscription = {
	__typename?: "UserPaymentSubscription";
	default?: Maybe<PaymentMethodSubscription>;
	options?: Maybe<Array<PaymentMethodSubscription>>;
};

export type UserPaymentSubscriptionUpdate = {
	default?: InputMaybe<PaymentMethodSubscriptionUpdate>;
	options?: InputMaybe<Array<PaymentMethodSubscriptionUpdate>>;
};

export type UserPaymentUpdate = {
	default?: InputMaybe<PaymentMethodUpdate>;
	options?: InputMaybe<Array<PaymentMethodUpdate>>;
};

export type UserProfile = Profile & {
	__typename?: "UserProfile";
	email?: Maybe<Scalars["String"]["output"]>;
	id: Scalars["ID"]["output"];
	name?: Maybe<Scalars["String"]["output"]>;
	phone?: Maybe<Scalars["String"]["output"]>;
};

export type UserProfileLinkResponse = {
	__typename?: "UserProfileLinkResponse";
	error?: Maybe<Error>;
};

export type UserSecretLoginInput = {
	id: Scalars["String"]["input"];
	secret: Scalars["String"]["input"];
};

export type UserSubscription = {
	__typename?: "UserSubscription";
	id: Scalars["ID"]["output"];
	name?: Maybe<Scalars["String"]["output"]>;
	/** @deprecated Use transaction.orders.last instead. */
	orders?: Maybe<Array<OrderSubscription>>;
	payment?: Maybe<UserPaymentSubscription>;
	transaction?: Maybe<FridgeTransactionSubscription>;
};

export type UserSubscriptionUpdate = {
	id: Scalars["ID"]["input"];
	name?: InputMaybe<Scalars["String"]["input"]>;
	orders?: InputMaybe<Array<OrderSubscriptionUpdate>>;
	payment?: InputMaybe<UserPaymentSubscriptionUpdate>;
	transaction?: InputMaybe<FridgeTransactionSubscriptionUpdate>;
};

export type UserUpdate = {
	id: Scalars["ID"]["input"];
	name?: InputMaybe<Scalars["String"]["input"]>;
	payment?: InputMaybe<UserPaymentUpdate>;
	transaction?: InputMaybe<FridgeTransactionUpdate>;
};

export type UpdateFridgeMutationVariables = Exact<{
	input: FridgeUpdate;
}>;

export type UpdateFridgeMutation = {
	__typename?: "Mutation";
	updateFridge: {
		__typename?: "Fridge";
		lighting?: { __typename?: "Lighting"; mode?: LightingModeType | null } | null;
	};
};

export type ToggleSpotlightMutationVariables = Exact<{
	input: FridgeUpdate;
}>;

export type ToggleSpotlightMutation = {
	__typename?: "Mutation";
	updateFridge: {
		__typename?: "Fridge";
		dishes?: {
			__typename?: "FridgeDishes";
			types?: Array<{
				__typename?: "DishType";
				id: string;
				lighting?: {
					__typename?: "DishTypeLighting";
					mode?: LightingSegmentModeType | null;
				} | null;
			}> | null;
		} | null;
	};
};

export type FridgeQueryVariables = Exact<{
	id: Scalars["ID"]["input"];
}>;

export type FridgeQuery = {
	__typename?: "Query";
	fridge: {
		__typename?: "Fridge";
		id: string;
		access?: {
			__typename?: "FridgeAccess";
			qrCode?: { __typename?: "QrCode"; code?: string | null } | null;
		} | null;
		dishes?: {
			__typename?: "FridgeDishes";
			types?: Array<{
				__typename?: "DishType";
				id: string;
				weight?: string | null;
				allergens?: Array<AllergenType> | null;
				description?: {
					__typename?: "DishTypeDescription";
					primary?: {
						__typename?: "DishTypeDescriptionDetail";
						composition?: string | null;
						description?: string | null;
						name?: string | null;
					} | null;
					secondary?: {
						__typename?: "DishTypeDescriptionDetail";
						composition?: string | null;
						description?: string | null;
						name?: string | null;
					} | null;
				} | null;
				image?: { __typename?: "Image"; url?: string | null } | null;
				price?: {
					__typename?: "Price";
					default?: string | null;
					current?: string | null;
					currency?: CurrencyType | null;
				} | null;
				lighting?: {
					__typename?: "DishTypeLighting";
					mode?: LightingSegmentModeType | null;
				} | null;
				dishes?: Array<{
					__typename?: "Dish";
					id: string;
					expiration?: string | null;
					position?: DishSegmentType | null;
				}> | null;
			}> | null;
		} | null;
		door?: {
			__typename?: "FridgeDoor";
			state?: DoorState | null;
			lock?: {
				__typename?: "FridgeDoorLock";
				state?: LockState | null;
				timeout?: number | null;
			} | null;
		} | null;
		lighting?: { __typename?: "Lighting"; mode?: LightingModeType | null } | null;
		transaction?: {
			__typename?: "FridgeTransaction";
			order?: {
				__typename?: "Order";
				id: string;
				type?: TransactionType | null;
				state?: TransactionState | null;
				user?: { __typename?: "User"; id: string; name?: string | null } | null;
				inventory?: {
					__typename?: "TransactionInventory";
					removed?: Array<{
						__typename?: "Dish";
						type?: { __typename?: "DishType"; id: string } | null;
					}> | null;
				} | null;
				payment?: { __typename?: "Payment"; type?: PaymentType | null } | null;
				price?: {
					__typename?: "OrderPrice";
					total?: {
						__typename?: "Price";
						default?: string | null;
						current?: string | null;
						currency?: CurrencyType | null;
					} | null;
				} | null;
			} | null;
		} | null;
		segments?: Array<{
			__typename?: "FridgeSegment";
			segment?: {
				__typename?: "DishSegment";
				type?: DishSegmentType | null;
				capacity?: number | null;
			} | null;
		}> | null;
	};
};

export type FridgeSubscriptionSubscriptionVariables = Exact<{
	id: Scalars["ID"]["input"];
}>;

export type FridgeSubscriptionSubscription = {
	__typename?: "Subscription";
	fridge?: {
		__typename?: "FridgeSubscription";
		id: string;
		access?: {
			__typename?: "FridgeAccessSubscription";
			qrCode?: { __typename?: "QrCodeSubscription"; code?: string | null } | null;
		} | null;
		dishes?: {
			__typename?: "FridgeDishesSubscription";
			types?: Array<{
				__typename?: "DishTypeSubscription";
				id: string;
				weight?: string | null;
				allergens?: Array<AllergenType> | null;
				description?: {
					__typename?: "DishTypeDescriptionSubscription";
					primary?: {
						__typename?: "DishTypeDescriptionDetailSubscription";
						composition?: string | null;
						description?: string | null;
						name?: string | null;
					} | null;
					secondary?: {
						__typename?: "DishTypeDescriptionDetailSubscription";
						composition?: string | null;
						description?: string | null;
						name?: string | null;
					} | null;
				} | null;
				image?: { __typename?: "ImageSubscription"; url?: string | null } | null;
				price?: {
					__typename?: "PriceSubscription";
					default?: string | null;
					current?: string | null;
					currency?: CurrencyType | null;
				} | null;
				lighting?: {
					__typename?: "DishTypeLightingSubscription";
					mode?: LightingSegmentModeType | null;
				} | null;
				dishes?: Array<{
					__typename?: "DishSubscription";
					id: string;
					expiration?: string | null;
					position?: DishSegmentType | null;
				}> | null;
			}> | null;
		} | null;
		door?: {
			__typename?: "FridgeDoorSubscription";
			state?: DoorState | null;
			lock?: {
				__typename?: "FridgeDoorLockSubscription";
				state?: LockState | null;
				timeout?: number | null;
			} | null;
		} | null;
		lighting?: { __typename?: "LightingSubscription"; mode?: LightingModeType | null } | null;
		transaction?: {
			__typename?: "FridgeTransactionSubscription";
			order?: {
				__typename?: "OrderSubscription";
				id: string;
				type?: TransactionType | null;
				state?: TransactionState | null;
				user?: { __typename?: "UserSubscription"; id: string; name?: string | null } | null;
				inventory?: {
					__typename?: "TransactionInventorySubscription";
					removed?: Array<{
						__typename?: "DishSubscription";
						id: string;
						type?: { __typename?: "DishTypeSubscription"; id: string } | null;
					}> | null;
					added?: Array<{
						__typename?: "DishSubscription";
						id: string;
						type?: { __typename?: "DishTypeSubscription"; id: string } | null;
					}> | null;
					updated?: Array<{
						__typename?: "DishSubscription";
						id: string;
						type?: { __typename?: "DishTypeSubscription"; id: string } | null;
					}> | null;
				} | null;
				payment?: { __typename?: "PaymentSubscription"; type?: PaymentType | null } | null;
				price?: {
					__typename?: "OrderPriceSubscription";
					total?: {
						__typename?: "PriceSubscription";
						default?: string | null;
						current?: string | null;
						currency?: CurrencyType | null;
					} | null;
				} | null;
			} | null;
		} | null;
		segments?: Array<{
			__typename?: "FridgeSegmentSubscription";
			segment?: {
				__typename?: "DishSegmentSubscription";
				type?: DishSegmentType | null;
				capacity?: number | null;
			} | null;
		}> | null;
	} | null;
};

export type SendEmptyOrderFeedbackMutationVariables = Exact<{
	input: FridgeUpdate;
}>;

export type SendEmptyOrderFeedbackMutation = {
	__typename?: "Mutation";
	updateFridge: {
		__typename?: "Fridge";
		dishes?: {
			__typename?: "FridgeDishes";
			types?: Array<{
				__typename?: "DishType";
				id: string;
				lighting?: {
					__typename?: "DishTypeLighting";
					mode?: LightingSegmentModeType | null;
				} | null;
			}> | null;
		} | null;
	};
};

export const UpdateFridgeDocument = {
	kind: "Document",
	definitions: [
		{
			kind: "OperationDefinition",
			operation: "mutation",
			name: { kind: "Name", value: "UpdateFridge" },
			variableDefinitions: [
				{
					kind: "VariableDefinition",
					variable: { kind: "Variable", name: { kind: "Name", value: "input" } },
					type: {
						kind: "NonNullType",
						type: { kind: "NamedType", name: { kind: "Name", value: "FridgeUpdate" } },
					},
				},
			],
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{
						kind: "Field",
						name: { kind: "Name", value: "updateFridge" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "input" },
								value: { kind: "Variable", name: { kind: "Name", value: "input" } },
							},
						],
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{
									kind: "Field",
									name: { kind: "Name", value: "lighting" },
									selectionSet: {
										kind: "SelectionSet",
										selections: [
											{
												kind: "Field",
												name: { kind: "Name", value: "mode" },
											},
										],
									},
								},
							],
						},
					},
				],
			},
		},
	],
} as unknown as DocumentNode<UpdateFridgeMutation, UpdateFridgeMutationVariables>;
export const ToggleSpotlightDocument = {
	kind: "Document",
	definitions: [
		{
			kind: "OperationDefinition",
			operation: "mutation",
			name: { kind: "Name", value: "ToggleSpotlight" },
			variableDefinitions: [
				{
					kind: "VariableDefinition",
					variable: { kind: "Variable", name: { kind: "Name", value: "input" } },
					type: {
						kind: "NonNullType",
						type: { kind: "NamedType", name: { kind: "Name", value: "FridgeUpdate" } },
					},
				},
			],
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{
						kind: "Field",
						name: { kind: "Name", value: "updateFridge" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "input" },
								value: { kind: "Variable", name: { kind: "Name", value: "input" } },
							},
						],
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{
									kind: "Field",
									name: { kind: "Name", value: "dishes" },
									selectionSet: {
										kind: "SelectionSet",
										selections: [
											{
												kind: "Field",
												name: { kind: "Name", value: "types" },
												selectionSet: {
													kind: "SelectionSet",
													selections: [
														{
															kind: "Field",
															alias: { kind: "Name", value: "id" },
															name: { kind: "Name", value: "id" },
														},
														{
															kind: "Field",
															name: {
																kind: "Name",
																value: "lighting",
															},
															selectionSet: {
																kind: "SelectionSet",
																selections: [
																	{
																		kind: "Field",
																		alias: {
																			kind: "Name",
																			value: "mode",
																		},
																		name: {
																			kind: "Name",
																			value: "mode",
																		},
																	},
																],
															},
														},
													],
												},
											},
										],
									},
								},
							],
						},
					},
				],
			},
		},
	],
} as unknown as DocumentNode<ToggleSpotlightMutation, ToggleSpotlightMutationVariables>;
export const FridgeDocument = {
	kind: "Document",
	definitions: [
		{
			kind: "OperationDefinition",
			operation: "query",
			name: { kind: "Name", value: "Fridge" },
			variableDefinitions: [
				{
					kind: "VariableDefinition",
					variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
					type: {
						kind: "NonNullType",
						type: { kind: "NamedType", name: { kind: "Name", value: "ID" } },
					},
				},
			],
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{
						kind: "Field",
						name: { kind: "Name", value: "fridge" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "id" },
								value: { kind: "Variable", name: { kind: "Name", value: "id" } },
							},
						],
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{ kind: "Field", name: { kind: "Name", value: "id" } },
								{
									kind: "Field",
									name: { kind: "Name", value: "access" },
									selectionSet: {
										kind: "SelectionSet",
										selections: [
											{
												kind: "Field",
												name: { kind: "Name", value: "qrCode" },
												selectionSet: {
													kind: "SelectionSet",
													selections: [
														{
															kind: "Field",
															name: { kind: "Name", value: "code" },
														},
													],
												},
											},
										],
									},
								},
								{
									kind: "Field",
									name: { kind: "Name", value: "dishes" },
									selectionSet: {
										kind: "SelectionSet",
										selections: [
											{
												kind: "Field",
												name: { kind: "Name", value: "types" },
												selectionSet: {
													kind: "SelectionSet",
													selections: [
														{
															kind: "Field",
															name: { kind: "Name", value: "id" },
														},
														{
															kind: "Field",
															name: {
																kind: "Name",
																value: "description",
															},
															selectionSet: {
																kind: "SelectionSet",
																selections: [
																	{
																		kind: "Field",
																		name: {
																			kind: "Name",
																			value: "primary",
																		},
																		selectionSet: {
																			kind: "SelectionSet",
																			selections: [
																				{
																					kind: "Field",
																					name: {
																						kind: "Name",
																						value: "composition",
																					},
																				},
																				{
																					kind: "Field",
																					name: {
																						kind: "Name",
																						value: "description",
																					},
																				},
																				{
																					kind: "Field",
																					name: {
																						kind: "Name",
																						value: "name",
																					},
																				},
																			],
																		},
																	},
																	{
																		kind: "Field",
																		name: {
																			kind: "Name",
																			value: "secondary",
																		},
																		selectionSet: {
																			kind: "SelectionSet",
																			selections: [
																				{
																					kind: "Field",
																					name: {
																						kind: "Name",
																						value: "composition",
																					},
																				},
																				{
																					kind: "Field",
																					name: {
																						kind: "Name",
																						value: "description",
																					},
																				},
																				{
																					kind: "Field",
																					name: {
																						kind: "Name",
																						value: "name",
																					},
																				},
																			],
																		},
																	},
																],
															},
														},
														{
															kind: "Field",
															name: { kind: "Name", value: "image" },
															selectionSet: {
																kind: "SelectionSet",
																selections: [
																	{
																		kind: "Field",
																		name: {
																			kind: "Name",
																			value: "url",
																		},
																	},
																],
															},
														},
														{
															kind: "Field",
															name: { kind: "Name", value: "price" },
															selectionSet: {
																kind: "SelectionSet",
																selections: [
																	{
																		kind: "Field",
																		name: {
																			kind: "Name",
																			value: "default",
																		},
																	},
																	{
																		kind: "Field",
																		name: {
																			kind: "Name",
																			value: "current",
																		},
																	},
																	{
																		kind: "Field",
																		name: {
																			kind: "Name",
																			value: "currency",
																		},
																	},
																],
															},
														},
														{
															kind: "Field",
															name: { kind: "Name", value: "weight" },
														},
														{
															kind: "Field",
															name: {
																kind: "Name",
																value: "allergens",
															},
														},
														{
															kind: "Field",
															name: {
																kind: "Name",
																value: "lighting",
															},
															selectionSet: {
																kind: "SelectionSet",
																selections: [
																	{
																		kind: "Field",
																		name: {
																			kind: "Name",
																			value: "mode",
																		},
																	},
																],
															},
														},
														{
															kind: "Field",
															name: { kind: "Name", value: "dishes" },
															selectionSet: {
																kind: "SelectionSet",
																selections: [
																	{
																		kind: "Field",
																		name: {
																			kind: "Name",
																			value: "id",
																		},
																	},
																	{
																		kind: "Field",
																		name: {
																			kind: "Name",
																			value: "expiration",
																		},
																	},
																	{
																		kind: "Field",
																		name: {
																			kind: "Name",
																			value: "position",
																		},
																	},
																],
															},
														},
													],
												},
											},
										],
									},
								},
								{
									kind: "Field",
									name: { kind: "Name", value: "door" },
									selectionSet: {
										kind: "SelectionSet",
										selections: [
											{
												kind: "Field",
												name: { kind: "Name", value: "state" },
											},
											{
												kind: "Field",
												name: { kind: "Name", value: "lock" },
												selectionSet: {
													kind: "SelectionSet",
													selections: [
														{
															kind: "Field",
															name: { kind: "Name", value: "state" },
														},
														{
															kind: "Field",
															name: {
																kind: "Name",
																value: "timeout",
															},
														},
													],
												},
											},
										],
									},
								},
								{
									kind: "Field",
									name: { kind: "Name", value: "lighting" },
									selectionSet: {
										kind: "SelectionSet",
										selections: [
											{
												kind: "Field",
												name: { kind: "Name", value: "mode" },
											},
										],
									},
								},
								{
									kind: "Field",
									name: { kind: "Name", value: "transaction" },
									selectionSet: {
										kind: "SelectionSet",
										selections: [
											{
												kind: "Field",
												name: { kind: "Name", value: "order" },
												selectionSet: {
													kind: "SelectionSet",
													selections: [
														{
															kind: "Field",
															name: { kind: "Name", value: "id" },
														},
														{
															kind: "Field",
															name: { kind: "Name", value: "type" },
														},
														{
															kind: "Field",
															name: { kind: "Name", value: "state" },
														},
														{
															kind: "Field",
															name: { kind: "Name", value: "user" },
															selectionSet: {
																kind: "SelectionSet",
																selections: [
																	{
																		kind: "Field",
																		name: {
																			kind: "Name",
																			value: "id",
																		},
																	},
																	{
																		kind: "Field",
																		name: {
																			kind: "Name",
																			value: "name",
																		},
																	},
																],
															},
														},
														{
															kind: "Field",
															name: {
																kind: "Name",
																value: "inventory",
															},
															selectionSet: {
																kind: "SelectionSet",
																selections: [
																	{
																		kind: "Field",
																		name: {
																			kind: "Name",
																			value: "removed",
																		},
																		selectionSet: {
																			kind: "SelectionSet",
																			selections: [
																				{
																					kind: "Field",
																					name: {
																						kind: "Name",
																						value: "type",
																					},
																					selectionSet: {
																						kind: "SelectionSet",
																						selections:
																							[
																								{
																									kind: "Field",
																									name: {
																										kind: "Name",
																										value: "id",
																									},
																								},
																							],
																					},
																				},
																			],
																		},
																	},
																],
															},
														},
														{
															kind: "Field",
															name: {
																kind: "Name",
																value: "payment",
															},
															selectionSet: {
																kind: "SelectionSet",
																selections: [
																	{
																		kind: "Field",
																		name: {
																			kind: "Name",
																			value: "type",
																		},
																	},
																],
															},
														},
														{
															kind: "Field",
															name: { kind: "Name", value: "price" },
															selectionSet: {
																kind: "SelectionSet",
																selections: [
																	{
																		kind: "Field",
																		name: {
																			kind: "Name",
																			value: "total",
																		},
																		selectionSet: {
																			kind: "SelectionSet",
																			selections: [
																				{
																					kind: "Field",
																					name: {
																						kind: "Name",
																						value: "default",
																					},
																				},
																				{
																					kind: "Field",
																					name: {
																						kind: "Name",
																						value: "current",
																					},
																				},
																				{
																					kind: "Field",
																					name: {
																						kind: "Name",
																						value: "currency",
																					},
																				},
																			],
																		},
																	},
																],
															},
														},
													],
												},
											},
										],
									},
								},
								{
									kind: "Field",
									name: { kind: "Name", value: "segments" },
									selectionSet: {
										kind: "SelectionSet",
										selections: [
											{
												kind: "Field",
												name: { kind: "Name", value: "segment" },
												selectionSet: {
													kind: "SelectionSet",
													selections: [
														{
															kind: "Field",
															name: { kind: "Name", value: "type" },
														},
														{
															kind: "Field",
															name: {
																kind: "Name",
																value: "capacity",
															},
														},
													],
												},
											},
										],
									},
								},
							],
						},
					},
				],
			},
		},
	],
} as unknown as DocumentNode<FridgeQuery, FridgeQueryVariables>;
export const FridgeSubscriptionDocument = {
	kind: "Document",
	definitions: [
		{
			kind: "OperationDefinition",
			operation: "subscription",
			name: { kind: "Name", value: "FridgeSubscription" },
			variableDefinitions: [
				{
					kind: "VariableDefinition",
					variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
					type: {
						kind: "NonNullType",
						type: { kind: "NamedType", name: { kind: "Name", value: "ID" } },
					},
				},
			],
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{
						kind: "Field",
						name: { kind: "Name", value: "fridge" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "id" },
								value: { kind: "Variable", name: { kind: "Name", value: "id" } },
							},
						],
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{ kind: "Field", name: { kind: "Name", value: "id" } },
								{
									kind: "Field",
									name: { kind: "Name", value: "access" },
									selectionSet: {
										kind: "SelectionSet",
										selections: [
											{
												kind: "Field",
												name: { kind: "Name", value: "qrCode" },
												selectionSet: {
													kind: "SelectionSet",
													selections: [
														{
															kind: "Field",
															name: { kind: "Name", value: "code" },
														},
													],
												},
											},
										],
									},
								},
								{
									kind: "Field",
									name: { kind: "Name", value: "dishes" },
									selectionSet: {
										kind: "SelectionSet",
										selections: [
											{
												kind: "Field",
												name: { kind: "Name", value: "types" },
												selectionSet: {
													kind: "SelectionSet",
													selections: [
														{
															kind: "Field",
															name: { kind: "Name", value: "id" },
														},
														{
															kind: "Field",
															name: {
																kind: "Name",
																value: "description",
															},
															selectionSet: {
																kind: "SelectionSet",
																selections: [
																	{
																		kind: "Field",
																		name: {
																			kind: "Name",
																			value: "primary",
																		},
																		selectionSet: {
																			kind: "SelectionSet",
																			selections: [
																				{
																					kind: "Field",
																					name: {
																						kind: "Name",
																						value: "composition",
																					},
																				},
																				{
																					kind: "Field",
																					name: {
																						kind: "Name",
																						value: "description",
																					},
																				},
																				{
																					kind: "Field",
																					name: {
																						kind: "Name",
																						value: "name",
																					},
																				},
																			],
																		},
																	},
																	{
																		kind: "Field",
																		name: {
																			kind: "Name",
																			value: "secondary",
																		},
																		selectionSet: {
																			kind: "SelectionSet",
																			selections: [
																				{
																					kind: "Field",
																					name: {
																						kind: "Name",
																						value: "composition",
																					},
																				},
																				{
																					kind: "Field",
																					name: {
																						kind: "Name",
																						value: "description",
																					},
																				},
																				{
																					kind: "Field",
																					name: {
																						kind: "Name",
																						value: "name",
																					},
																				},
																			],
																		},
																	},
																],
															},
														},
														{
															kind: "Field",
															name: { kind: "Name", value: "image" },
															selectionSet: {
																kind: "SelectionSet",
																selections: [
																	{
																		kind: "Field",
																		name: {
																			kind: "Name",
																			value: "url",
																		},
																	},
																],
															},
														},
														{
															kind: "Field",
															name: { kind: "Name", value: "price" },
															selectionSet: {
																kind: "SelectionSet",
																selections: [
																	{
																		kind: "Field",
																		name: {
																			kind: "Name",
																			value: "default",
																		},
																	},
																	{
																		kind: "Field",
																		name: {
																			kind: "Name",
																			value: "current",
																		},
																	},
																	{
																		kind: "Field",
																		name: {
																			kind: "Name",
																			value: "currency",
																		},
																	},
																],
															},
														},
														{
															kind: "Field",
															name: { kind: "Name", value: "weight" },
														},
														{
															kind: "Field",
															name: {
																kind: "Name",
																value: "allergens",
															},
														},
														{
															kind: "Field",
															name: {
																kind: "Name",
																value: "lighting",
															},
															selectionSet: {
																kind: "SelectionSet",
																selections: [
																	{
																		kind: "Field",
																		name: {
																			kind: "Name",
																			value: "mode",
																		},
																	},
																],
															},
														},
														{
															kind: "Field",
															name: { kind: "Name", value: "dishes" },
															selectionSet: {
																kind: "SelectionSet",
																selections: [
																	{
																		kind: "Field",
																		name: {
																			kind: "Name",
																			value: "id",
																		},
																	},
																	{
																		kind: "Field",
																		name: {
																			kind: "Name",
																			value: "expiration",
																		},
																	},
																	{
																		kind: "Field",
																		name: {
																			kind: "Name",
																			value: "position",
																		},
																	},
																],
															},
														},
													],
												},
											},
										],
									},
								},
								{
									kind: "Field",
									name: { kind: "Name", value: "door" },
									selectionSet: {
										kind: "SelectionSet",
										selections: [
											{
												kind: "Field",
												name: { kind: "Name", value: "state" },
											},
											{
												kind: "Field",
												name: { kind: "Name", value: "lock" },
												selectionSet: {
													kind: "SelectionSet",
													selections: [
														{
															kind: "Field",
															name: { kind: "Name", value: "state" },
														},
														{
															kind: "Field",
															name: {
																kind: "Name",
																value: "timeout",
															},
														},
													],
												},
											},
										],
									},
								},
								{
									kind: "Field",
									name: { kind: "Name", value: "lighting" },
									selectionSet: {
										kind: "SelectionSet",
										selections: [
											{
												kind: "Field",
												name: { kind: "Name", value: "mode" },
											},
										],
									},
								},
								{
									kind: "Field",
									name: { kind: "Name", value: "transaction" },
									selectionSet: {
										kind: "SelectionSet",
										selections: [
											{
												kind: "Field",
												name: { kind: "Name", value: "order" },
												selectionSet: {
													kind: "SelectionSet",
													selections: [
														{
															kind: "Field",
															name: { kind: "Name", value: "id" },
														},
														{
															kind: "Field",
															name: { kind: "Name", value: "type" },
														},
														{
															kind: "Field",
															name: { kind: "Name", value: "state" },
														},
														{
															kind: "Field",
															name: { kind: "Name", value: "user" },
															selectionSet: {
																kind: "SelectionSet",
																selections: [
																	{
																		kind: "Field",
																		name: {
																			kind: "Name",
																			value: "id",
																		},
																	},
																	{
																		kind: "Field",
																		name: {
																			kind: "Name",
																			value: "name",
																		},
																	},
																],
															},
														},
														{
															kind: "Field",
															name: {
																kind: "Name",
																value: "inventory",
															},
															selectionSet: {
																kind: "SelectionSet",
																selections: [
																	{
																		kind: "Field",
																		name: {
																			kind: "Name",
																			value: "removed",
																		},
																		selectionSet: {
																			kind: "SelectionSet",
																			selections: [
																				{
																					kind: "Field",
																					name: {
																						kind: "Name",
																						value: "id",
																					},
																				},
																				{
																					kind: "Field",
																					name: {
																						kind: "Name",
																						value: "type",
																					},
																					selectionSet: {
																						kind: "SelectionSet",
																						selections:
																							[
																								{
																									kind: "Field",
																									name: {
																										kind: "Name",
																										value: "id",
																									},
																								},
																							],
																					},
																				},
																			],
																		},
																	},
																	{
																		kind: "Field",
																		name: {
																			kind: "Name",
																			value: "added",
																		},
																		selectionSet: {
																			kind: "SelectionSet",
																			selections: [
																				{
																					kind: "Field",
																					name: {
																						kind: "Name",
																						value: "id",
																					},
																				},
																				{
																					kind: "Field",
																					name: {
																						kind: "Name",
																						value: "type",
																					},
																					selectionSet: {
																						kind: "SelectionSet",
																						selections:
																							[
																								{
																									kind: "Field",
																									name: {
																										kind: "Name",
																										value: "id",
																									},
																								},
																							],
																					},
																				},
																			],
																		},
																	},
																	{
																		kind: "Field",
																		name: {
																			kind: "Name",
																			value: "updated",
																		},
																		selectionSet: {
																			kind: "SelectionSet",
																			selections: [
																				{
																					kind: "Field",
																					name: {
																						kind: "Name",
																						value: "id",
																					},
																				},
																				{
																					kind: "Field",
																					name: {
																						kind: "Name",
																						value: "type",
																					},
																					selectionSet: {
																						kind: "SelectionSet",
																						selections:
																							[
																								{
																									kind: "Field",
																									name: {
																										kind: "Name",
																										value: "id",
																									},
																								},
																							],
																					},
																				},
																			],
																		},
																	},
																],
															},
														},
														{
															kind: "Field",
															name: {
																kind: "Name",
																value: "payment",
															},
															selectionSet: {
																kind: "SelectionSet",
																selections: [
																	{
																		kind: "Field",
																		name: {
																			kind: "Name",
																			value: "type",
																		},
																	},
																],
															},
														},
														{
															kind: "Field",
															name: { kind: "Name", value: "price" },
															selectionSet: {
																kind: "SelectionSet",
																selections: [
																	{
																		kind: "Field",
																		name: {
																			kind: "Name",
																			value: "total",
																		},
																		selectionSet: {
																			kind: "SelectionSet",
																			selections: [
																				{
																					kind: "Field",
																					name: {
																						kind: "Name",
																						value: "default",
																					},
																				},
																				{
																					kind: "Field",
																					name: {
																						kind: "Name",
																						value: "current",
																					},
																				},
																				{
																					kind: "Field",
																					name: {
																						kind: "Name",
																						value: "currency",
																					},
																				},
																			],
																		},
																	},
																],
															},
														},
													],
												},
											},
										],
									},
								},
								{
									kind: "Field",
									name: { kind: "Name", value: "segments" },
									selectionSet: {
										kind: "SelectionSet",
										selections: [
											{
												kind: "Field",
												name: { kind: "Name", value: "segment" },
												selectionSet: {
													kind: "SelectionSet",
													selections: [
														{
															kind: "Field",
															name: { kind: "Name", value: "type" },
														},
														{
															kind: "Field",
															name: {
																kind: "Name",
																value: "capacity",
															},
														},
													],
												},
											},
										],
									},
								},
							],
						},
					},
				],
			},
		},
	],
} as unknown as DocumentNode<
	FridgeSubscriptionSubscription,
	FridgeSubscriptionSubscriptionVariables
>;
export const SendEmptyOrderFeedbackDocument = {
	kind: "Document",
	definitions: [
		{
			kind: "OperationDefinition",
			operation: "mutation",
			name: { kind: "Name", value: "SendEmptyOrderFeedback" },
			variableDefinitions: [
				{
					kind: "VariableDefinition",
					variable: { kind: "Variable", name: { kind: "Name", value: "input" } },
					type: {
						kind: "NonNullType",
						type: { kind: "NamedType", name: { kind: "Name", value: "FridgeUpdate" } },
					},
				},
			],
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{
						kind: "Field",
						name: { kind: "Name", value: "updateFridge" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "input" },
								value: { kind: "Variable", name: { kind: "Name", value: "input" } },
							},
						],
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{
									kind: "Field",
									name: { kind: "Name", value: "dishes" },
									selectionSet: {
										kind: "SelectionSet",
										selections: [
											{
												kind: "Field",
												name: { kind: "Name", value: "types" },
												selectionSet: {
													kind: "SelectionSet",
													selections: [
														{
															kind: "Field",
															alias: { kind: "Name", value: "id" },
															name: { kind: "Name", value: "id" },
														},
														{
															kind: "Field",
															name: {
																kind: "Name",
																value: "lighting",
															},
															selectionSet: {
																kind: "SelectionSet",
																selections: [
																	{
																		kind: "Field",
																		alias: {
																			kind: "Name",
																			value: "mode",
																		},
																		name: {
																			kind: "Name",
																			value: "mode",
																		},
																	},
																],
															},
														},
													],
												},
											},
										],
									},
								},
							],
						},
					},
				],
			},
		},
	],
} as unknown as DocumentNode<
	SendEmptyOrderFeedbackMutation,
	SendEmptyOrderFeedbackMutationVariables
>;
