import { DishTypeCategory, MealListSortDirectionEnum, MealListSortTypeEnum } from "types/mainTypes";

import { env } from "./env";

const debug = {
	fridge1ID: env.VITE_FRIDGE_1_ID,
	fridge2ID: env.VITE_FRIDGE_2_ID,
	virtualFridgeID: env.VITE_VIRTUAL_FRIDGE_ID,
	reviewFridgeID: env.VITE_REVIEW_FRIDGE_ID,
	devCarryFridgeId: env.VITE_DEV_CARRY_FRIDGE_ID,
};

const alarms = {
	totalAlarmTimeout: env.VITE_TOTAL_ALARM_TIMEOUT,
	refillWarningTimeout: env.VITE_REFILL_DOOR_WARNING_DELAY,
	displayWarningTimeout: env.VITE_DISPLAY_WARNING_TIMEOUT,
	firstAlarm: {
		timeout: env.VITE_FIRST_ALARM_TIMEOUT,
		sound: env.VITE_ALARM_SOUND_1_URL,
	},
	secondAlarm: {
		timeout: env.VITE_SECOND_ALARM_TIMEOUT,
		sound: env.VITE_ALARM_SOUND_2_URL,
	},
	thirdAlarm: {
		timeout: env.VITE_THIRD_ALARM_TIMEOUT,
		sound: env.VITE_ALARM_SOUND_3_URL,
	},
};

const tests = {
	MSW_ENABLED: env.VITE_MSW_ENABLED,
	useMockData: env.VITE_USE_MOCK_DATA,
};

const connectionSettings = {
	connectionTimeout: env.VITE_CONNECTION_TIMEOUT,
	initialStateQueryRefetchInterval: env.VITE_INITIAL_STATE_QUERY_REFETCH_INTERVAL_MS,
	initialStateQueryRefetchDebounce: env.VITE_INITIAL_STATE_QUERY_REFETCH_DEBOUNCE_RANGE_MS,
};

const activityMonitor = {
	inactiveTimeout: env.VITE_INACTIVE_TIMEOUT,
	orderSummaryRedirectTimeout: 10000,
};

const tools = {
	mixpanelProjectId: env.VITE_MIXPANEL_PROJECT_ID,
	sentryDSN: env.VITE_SENTRY_DSN,
};

const aws = {
	apiKey: env.VITE_AWS_APPSYNC_API_KEY,
	appsyncUrl: env.VITE_AWS_APPSYNC_URL,
	region: env.VITE_AWS_REGION,
};

const common = {
	dishTypePlaceholderImage: env.VITE_PLACEHOLDER_DISHTYPE_IMAGE_URL,
	defaultLocale: "cs-CZ",
	localeLocalStorageKey: "locale",
};

const categoryOrder = {
	[DishTypeCategory.MEAL]: 0,
	[DishTypeCategory.SOUP]: 1,
	[DishTypeCategory.SNACK]: 2,
};

const mealListOrder = {
	categoryOrder,
	dishTypeOrder: MealListSortTypeEnum.NAME, // PRICE | NAME
	dishTypeOrderDirection: MealListSortDirectionEnum.ASCENDING, // ASC | DESC
};

const envConfig = {
	activityMonitor,
	alarms,
	aws,
	categoryOrder,
	common,
	connectionSettings,
	debug,
	mealListOrder,
	tests,
	tools,
};

export const Config = {
	...envConfig,
} as const;
