import { selectFilters, setFilters } from "slices/appStateSlice";

import { Filters } from "types/mainTypes";

import { useAppDispatch, useAppSelector } from "./utilsHooks/storeHooks";

export const useFilters = () => {
	const filters = useAppSelector(selectFilters);
	const dispatch = useAppDispatch();

	const handleSetFilters = (payload: Filters) => {
		if (payload.allergens) {
			dispatch(setFilters(payload));
		}
	};

	const resetAllergens = () => {
		if (filters.allergens.length > 0) {
			dispatch(setFilters({ allergens: [] }));
		}
	};

	return {
		allergens: filters.allergens,
		setFilters: handleSetFilters,
		resetAllergens,
	};
};
