import { Box, Typography, styled } from "@mui/material";
import { changeLanguage } from "i18next";
import React, { RefObject, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import {
	selectAppState,
	selectOpenedDetailDishTypeId,
	selectSelectedLanguage,
	setSelectedLanguage,
} from "slices/appStateSlice";
import { getSpotlightDishTypeId } from "utils/lightingUtils";
import { filterAvailableDishTypes, sortDishTypes } from "utils/mealListUtils";

import NoFiltersMatchImage from "assets/illustrations/noMatchFiltersImage.svg";
import DishTypeItem from "components/dishTypeItem";
import { TutorialModalContent } from "components/drawerActionBar/components/TutorialModal/TutorialModalContent";
import { Header } from "components/global/components/Header";
import { BasicModal } from "components/global/modals/BasicModal";
import { useFilters } from "hooks/useFilters";
import { useFridgeLights } from "hooks/useFridgeLights";
import useMealListEvents from "hooks/useMealListEvents";
import { useOrder } from "hooks/useOrder";
import { useAppDispatch, useAppSelector } from "hooks/utilsHooks/storeHooks";
import { LightingModeType, TransactionState } from "types";
import { AppLanguage, AppState, DishItemDisplayType, LocalDishType } from "types/mainTypes";

import { DownloadAppBanner } from "../howToBanner/DownloadAppBanner";

/**
 * Represents the properties for the MealList component.
 */
type MealListProps = {
	fridgeDishes: LocalDishType[];
	availableDishTypeIds: Set<string>;
};

/**
 * Represents a component that displays a list of meal types.
 **/
export const MealList = ({ fridgeDishes, availableDishTypeIds }: MealListProps) => {
	const openDetailDishTypeId = useAppSelector(selectOpenedDetailDishTypeId);
	const selectedLanguage = useAppSelector(selectSelectedLanguage);
	const dispatch = useAppDispatch();
	const appState = useAppSelector(selectAppState);

	const { allergens } = useFilters();
	const { toggleLight, dishTypeLights, toggleFridgeLightingMode, highlightSingleDishType } =
		useFridgeLights();
	const {
		handleOpen,
		closeOpenedDishTypeDetail,
		handleLightsStateWhenFilterIsActive,
		openDishTypeDetail,
	} = useMealListEvents(availableDishTypeIds, fridgeDishes);
	const { order } = useOrder();
	const { t } = useTranslation();
	const [availableDishTypes, setAvailableDishTypes] = useState<LocalDishType[]>([]);
	const [isHowToBannerVisible, setIsHowToBannerVisible] = useState(true);
	const howToBannerRef = useRef<HTMLDivElement>(null);

	const { resetAllergens } = useFilters();

	const handleHowToBannerVisibility = (state?: boolean) => {
		if (state !== undefined && state !== null) {
			setIsHowToBannerVisible(state);
		} else {
			setIsHowToBannerVisible((prev) => !prev);
		}
	};

	useEffect(function setInitialOpenedDishType() {
		if (dishTypeLights) {
			const initialHighlightedDishTypeId = getSpotlightDishTypeId(dishTypeLights);
			if (initialHighlightedDishTypeId !== null && allergens.length === 0) {
				openDishTypeDetail(initialHighlightedDishTypeId);
			}
		}
	}, []);

	useEffect(
		function setupAvailableDishTypes() {
			const filteredDishes = filterAvailableDishTypes(
				fridgeDishes,
				availableDishTypeIds,
				allergens,
			);
			const sortedDishes = sortDishTypes(filteredDishes, selectedLanguage);
			setAvailableDishTypes(sortedDishes);
		},
		[allergens, availableDishTypeIds, selectedLanguage],
	);

	useEffect(
		function handleFridgeLightAndDishDetailState() {
			// If user selects any filters, we highlight only filtered dish types
			if (allergens.length !== 0) {
				handleLightsStateWhenFilterIsActive();
				return;
			} else {
				if (appState === AppState.IDLE) {
					if (openDetailDishTypeId) {
						handleOpen(openDetailDishTypeId);
					}
					if (order?.state !== TransactionState.Finished) {
						toggleFridgeLightingMode(LightingModeType.Active);
						return;
					}
				}
			}
		},
		[allergens],
	);

	useEffect(
		function handleSingleDishTypeAction() {
			if (openDetailDishTypeId !== null && appState === AppState.ACTIVE) {
				highlightSingleDishType(toggleLight, availableDishTypeIds, openDetailDishTypeId);
				openDishTypeDetail(openDetailDishTypeId);
				return;
			}
			if (!openDetailDishTypeId && allergens.length > 0) {
				handleLightsStateWhenFilterIsActive();
				return;
			}
			if (!openDetailDishTypeId && appState === AppState.ACTIVE) {
				closeOpenedDishTypeDetail();
				toggleFridgeLightingMode(LightingModeType.Active);
			}
		},
		[openDetailDishTypeId],
	);

	useEffect(
		function resetViewWhenIdle() {
			if (!order) {
				closeOpenedDishTypeDetail();
				resetAllergens();
				handleHowToBannerVisibility(true);
				dispatch(setSelectedLanguage(AppLanguage.PRIMARY));
				changeLanguage("cs");
				const timeout = setTimeout(() => {
					howToBannerRef.current?.scrollIntoView({ behavior: "smooth", block: "start" });
				}, 100);
				return () => {
					window.clearTimeout(timeout);
				};
			}
			if (appState === AppState.IDLE && !order) {
				toggleFridgeLightingMode(LightingModeType.StandBy);
				closeOpenedDishTypeDetail();
			}
		},
		[appState, order],
	);

	useEffect(() => {
		if (order && isHowToBannerVisible) {
			handleHowToBannerVisibility(false);
		}
	}, [order]);

	const isFridgeEmpty = useMemo(() => {
		return fridgeDishes && availableDishTypes.length === 0 && allergens.length > 0;
	}, [fridgeDishes, availableDishTypes, allergens]);

	const memoizedHandleOpen = useCallback(
		(dishTypeId: string, ref: RefObject<HTMLDivElement>) => {
			handleOpen(dishTypeId, ref);
		},
		[handleOpen],
	);

	return (
		<>
			<Header />
			{isFridgeEmpty && (
				<StyledEmptyFridgePageContent>
					<StyledEmptyFridgeHeadline variant={"promoHeader"}>
						{t("dishListPage.filteredEmptyState")}
					</StyledEmptyFridgeHeadline>
					<NoFiltersMatchImage />
				</StyledEmptyFridgePageContent>
			)}
			<StyledDishTypeItemsWrapper data-testid="meal-list-dishes">
				<BasicModal
					toggleButton={
						<DownloadAppBanner
							ref={howToBannerRef}
							isVisible={isHowToBannerVisible}
							toggleBanner={handleHowToBannerVisibility}
						/>
					}
					closable={true}
					modalContent={<TutorialModalContent />}
					fullHeight={true}
				/>

				{availableDishTypes.map((dishType) => (
					<DishTypeItem
						dishType={dishType}
						type={DishItemDisplayType.LIST}
						isOpen={openDetailDishTypeId === dishType.id}
						onOpen={(ref: RefObject<HTMLDivElement>) =>
							memoizedHandleOpen(dishType.id, ref)
						}
						key={`mealListPage_item_${dishType.id}`}
					/>
				))}
			</StyledDishTypeItemsWrapper>
		</>
	);
};

const StyledDishTypeItemsWrapper = styled(Box)(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	gap: theme.spacing(2),
	overflow: "auto",
	height: "calc(100vh - 350px)",
	paddingBottom: "200px",
}));

const StyledEmptyFridgePageContent = styled(Box)(({ theme }) => ({
	width: "100%",
	height: "100%",
	margin: "auto",
	display: "flex",
	flexDirection: "column",
	alignItems: "center",
	paddingTop: theme.spacing(2.5),
}));

const StyledEmptyFridgeHeadline = styled(Typography)(({ theme }) => ({
	textAlign: "center",
	color: theme.palette.copper.main,
	marginBottom: theme.spacing(3),
}));
