import * as Sentry from "@sentry/react";
import { useEffect, useState } from "react";
import { useIdleTimerContext } from "react-idle-timer";

import { SnackbarContent } from "components/global/snackbar/SnackbarContent";
import { OrderStep1 } from "components/orderSteps/OrderStep1";
import { OrderStep2 } from "components/orderSteps/OrderStep2";
import { OrderStep3 } from "components/orderSteps/OrderStep3";
import { OrderStep5 } from "components/orderSteps/OrderStep5";
import { useDeviceContext } from "contexts/DeviceContext";
import { useSnackbarContext } from "contexts/SnackbarContext";
import { DoorState, LockState, TransactionState } from "types";
import { User } from "types/mainTypes";

import { useDishTypes } from "./useDishTypes";
import { useOrder } from "./useOrder";

export const useOrderSteps = ({
	open,
	toggleDrawer,
}: {
	open: boolean;
	toggleDrawer: (newState: boolean) => void;
}) => {
	const { order, orderDishes, orderSummary, finishOrder, paymentMethod } = useOrder();
	const { data: stock } = useDishTypes();
	const { door } = useDeviceContext();
	const [currentOrderStep, setCurrentOrderStep] = useState(1);
	const [hasBeenOpened, setHasBeenOpened] = useState(false);
	const { openSnackbarWithContent } = useSnackbarContext();
	const idleTimer = useIdleTimerContext();
	const { lock, state: doors } = door;

	useEffect(() => {
		const isClosedAndLocked = lock.state === LockState.Locked && doors === DoorState.Closed;
		const isClosedAndUnlocked = lock.state === LockState.Unlocked && doors === DoorState.Closed;
		if (!order) {
			setCurrentOrderStep(1);
			if (!isClosedAndLocked) {
				Sentry.captureException(new Error("Door opened without order"));
			}
		} else if (isClosedAndUnlocked && order?.inventory?.removed?.length === 0) {
			// If user has not interacted with the tablet, we activate the idle timer,
			// so the lights are turned on
			if (idleTimer.isIdle()) {
				idleTimer.activate();
			}
			setHasBeenOpened(false);
			setCurrentOrderStep(2);
		} else if (doors === DoorState.Opened && order?.state === TransactionState.Active) {
			// Set the flag to true, so we know that the fridge door has been opened
			setHasBeenOpened(true);
			setCurrentOrderStep(3);
		} else if (order && order.state === TransactionState.StockCheck) {
			// If the user opened the door, we show stock check screen,
			// otherwise we finish the order and return to the first step
			if (!hasBeenOpened) {
				setCurrentOrderStep(5);
				openSnackbarWithContent(
					<SnackbarContent
						variant="orderNotStarted"
						user={order?.user as User | undefined}
					/>,
				);
			}
		} else if (order && order.state === TransactionState.Finished) {
			// If the user opened the door, we show order summary screen, the order will have
			// this state even when user did not open the door, in that case, we do not show Order5
			if (currentOrderStep !== 5) {
				setCurrentOrderStep(5);
			}
		}
	}, [order?.state, order?.inventory, orderDishes, stock, doors, lock, hasBeenOpened]);

	switch (currentOrderStep) {
		case 1:
			return {
				currentOrderStep: 1,
				currentOrderStepScreen: <OrderStep1 open={open} toggleDrawer={toggleDrawer} />,
			};
		case 2:
			return {
				currentOrderStep: 2,
				currentOrderStepScreen: <OrderStep2 open={open} toggleDrawer={toggleDrawer} />,
			};
		case 3:
		case 4:
			return {
				currentOrderStep: 3,
				currentOrderStepScreen: (
					<OrderStep3
						open={open}
						orderDishes={orderDishes}
						orderDishTypes={orderSummary?.orderDishTypes}
						stock={stock ?? []}
						order={order}
						toggleDrawer={toggleDrawer}
					/>
				),
			};
		case 5:
			return {
				currentOrderStep: 5,
				currentOrderStepScreen: (
					<OrderStep5
						open={open}
						transactionId={order?.id as string}
						orderSummary={orderSummary}
						resetOrder={() => {
							finishOrder();
						}}
						order={order ?? null}
						toggleDrawer={toggleDrawer}
						paymentMethod={paymentMethod}
						doorHasBeenOpened={hasBeenOpened}
					/>
				),
			};
		default:
			return {
				currentOrderStep: 1,
				currentOrderStepScreen: <OrderStep1 open={true} toggleDrawer={toggleDrawer} />,
			};
	}
};
