import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { isArray } from "lodash";
import type { RootState } from "store";
import { mergeDishTypes } from "utils/utils";

import { LocalDishType, LocalFridgeDishes } from "types/mainTypes";

type DishesSlice = LocalFridgeDishes;

const initialState: DishesSlice = {
	types: [],
	items: [],
};

export const dishesSlice = createSlice({
	name: "dishes",
	initialState,
	reducers: {
		setInitialDishTypes: (state, action) => {
			state.types = action.payload;
		},
		setDishTypes: (state, { payload }: PayloadAction<LocalDishType[] | null>) => {
			if (!state.types) {
				if (payload) {
					state.types = payload;
				}
			} else if (isArray(payload)) {
				state.types = mergeDishTypes(state.types, payload);
			} else {
				if (payload) {
					state.types = mergeDishTypes(state.types, [payload]);
				}
			}
		},
	},
});

export const { setDishTypes, setInitialDishTypes } = dishesSlice.actions;
export const selectDishTypes = (state: RootState): LocalDishType[] => state.dishes.types || [];

export default dishesSlice.reducer;
