import { PresenceType } from "react-idle-timer";

import {
	AllergenType,
	Dish,
	DishTypeDescription,
	DishTypeLighting,
	DoorState,
	FridgeAccess,
	FridgeTransaction,
	Lighting,
	LightingSegmentModeType,
	LockState,
	Maybe,
	OrderPrice,
	Price,
	Scalars,
} from "types";

export enum DishItemDisplayType {
	LIST = "LIST",
	ORDER = "ORDER",
}

export enum DishTypeCategory {
	MEAL = "MEAL",
	SNACK = "SNACK",
	SOUP = "SOUP",
}

export type LocalDishType = {
	category?: DishTypeCategory | null;
	allergens?: Array<AllergenType> | null;
	description?: DishTypeDescription | null;
	dishes?: Array<Dish>;
	id: Scalars["ID"]["output"];
	image?: Image | null;
	lighting?: DishTypeLighting | null;
	price?: Price | null;
	weight?: Scalars["String"]["output"] | null;
};

export type LocalFridgeDishes = {
	types: LocalDishType[] | null;
	items: Dish[] | null;
};

export type LocalFridgeSegment = {
	segment: { type: string; capacity: number | null };
};

export type LocalFridgeState = {
	access: FridgeAccess | null;
	dishes: LocalFridgeDishes;
	door: FridgeDoorLockState;
	id: Scalars["ID"]["output"];
	name: Maybe<string>;
	location: string;
	lighting: Lighting | null;
	segments: LocalFridgeSegment[] | null;
	transaction: FridgeTransaction | null;
};

export type Image = {
	url: string;
};

export type Filters = {
	allergens: AllergenType[];
};

export type Error = {
	errorName: string;
	errorDescription: string;
	errorCode: string;
	isClosable: boolean;
};

export type FridgeDoorLockState = {
	state: DoorState | null;
	lock: {
		state: LockState | null;
		timeout: number | null;
	};
};

export type LocalDoorsAndLockState = {
	doors?: DoorState;
	lock?: LockState;
	lockTimeout?: number | null;
};

export type User = {
	readonly id: string;
	readonly name: string;
};

export type AppContextAction =
	| { type: "SET_LOCALE"; payload: string }
	| { type: "CHANGE_LANGUAGE"; payload: "primary" | "secondary" }
	| { type: "SET_FILTERS"; payload: Partial<Filters> }
	| { type: "SET_APP_STATE"; payload: PresenceType["type"] }
	| { type: "SET_OPENED_DETAIL_DISH_TYPE_ID"; payload: string | null }
	| { type: "DISABLE_TIMEOUTS" };

export type OrderSummary = {
	orderDishTypes: LocalDishType[];
	orderPrice?: Maybe<OrderPrice>;
};

export type DishTypeLightUpdate = {
	id: string;
	lighting: {
		mode: LightingSegmentModeType;
	};
};

export type ModalContentProps = {
	onClose?: () => void;
};

export type DishTypeLightMode = {
	id: string;
	lightingMode: Maybe<LightingSegmentModeType> | undefined;
};

export enum MealListSortTypeEnum {
	NAME = "NAME",
	PRICE = "PRICE",
}

export enum MealListSortDirectionEnum {
	ASCENDING = "ASC",
	DESCENDING = "DESC",
}

export enum AppLanguage {
	PRIMARY = "primary",
	SECONDARY = "secondary",
}

export enum AppState {
	ACTIVE = "active",
	IDLE = "idle",
}
